import { TouchEvent, MouseEvent, useCallback, useMemo, useRef } from "react"
import { isNil } from "lodash"
import { AxisBottom, Orientation } from "@visx/axis"
import { curveMonotoneX } from "@visx/curve"
import { localPoint } from "@visx/event"
import { Glyph } from "@visx/glyph"
import { LinearGradient } from "@visx/gradient"
import { scaleLinear, scaleTime } from "@visx/scale"
import { AreaClosed, Bar } from "@visx/shape"
import { Text } from "@visx/text"
import { useTooltip } from "@visx/tooltip"
import { bisector, max, min } from "d3-array"
import { ScaleTime } from "d3-scale"

import { WiSunrise, WiSunset } from "react-icons/wi"
import { useTrans } from "@/i18n"
import { Heading, P as Typography } from "../Typography"
import { ParentSize } from "./components/ParentSize"
import { Tooltip, TooltipLine, TooltipPoint } from "./components/Tooltip"
import { useLang } from "@/context/lang"

// Animations
import { AnimatePresence, motion } from "@/lib/animations"

// Datetime
import { timeFormat } from "@/lib/charts"

// Graphs
import { indexOfLastMatchInArray } from "../graphs/lib/data"

const getDate = (d: Data) => new Date(d.x)
const getX = (d: Data) => d.x
const getY = (d: Data) => d.y
const bisectDate = bisector<Data, Date>((d) => new Date(d.x)).left

export type TooltipData = { x: number; y: number }

// how big the "gap" at the end of the graph should be
const END_GAP = 50

type Data = {
	x: number
	y: number
}

type Margin = { top: number; right: number; bottom: number; left: number }

export type RealtimeProductionProps = {
	width: number
	height: number
	margin?: Margin
	accentColor?: string
	accentColorDark?: string
	onTooltip?: (data: TooltipData) => void
	data: Array<Data>
	displayTooltip?: boolean
	totalValue?: number | string | null
	valueUnit?: "wh" | "kwh" | "mwh"
	variant?: "truncated" | "full"
	// last value is the last value on the truncated axis timeline
	// so the graph will show 3 values: sunset, sunrise, and last value in the
	// dataset
	lastValue?: string | number
	sunrise: string | number
	sunset: string | number
}

export function RealtimeProductionContainer(
	props: Omit<RealtimeProductionProps, "width" | "height">,
) {
	return (
		<ParentSize>
			{({ width, height }) => {
				if (width < 10) return null
				return (
					<RealtimeProduction
						{...props}
						width={width}
						height={height}
					/>
				)
			}}
		</ParentSize>
	)
}

RealtimeProductionContainer.defaultMargin = {
	top: 0,
	left: 0,
	right: 0,
	bottom: 40,
}

export { RealtimeProductionContainer as RealtimeProduction }

function RealtimeProduction({
	width,
	height,
	margin = RealtimeProductionContainer.defaultMargin,
	accentColor = "#FFD900",
	onTooltip,
	data,
	lastValue,
	displayTooltip = true,
	variant = "full",
	totalValue,
	valueUnit = "kwh",
	sunrise,
	sunset,
}: RealtimeProductionProps) {
	const { current: id } = useRef(`realtime-production-graph-${Math.random()}`)
	const getId = (value: string) => `${id}-${value}`
	const { tooltipData, tooltipLeft, tooltipTop, showTooltip, hideTooltip } =
		useTooltip<TooltipData>()

	// Datetime (should be inside this component because it depends on the i18n hook)
	const formatDate = timeFormat("%I:%M %p")

	// Translations
	const t = useTrans()
	const { formatNumber } = useLang()

	// bounds
	const innerWidth = width - margin.left - margin.right
	const innerHeight = height - margin.top - margin.bottom

	// scales
	const xScale = useMemo(
		() =>
			scaleTime({
				range:
					variant === "truncated"
						? [margin.left, innerWidth + margin.left - END_GAP]
						: [margin.left, innerWidth + margin.left],
				domain: [min(data, getX) ?? 0, max(data, getX) ?? 0],
			}),
		[innerWidth, variant, data, margin.left],
	)

	const yScale = useMemo(
		() =>
			scaleLinear({
				range: [innerHeight + margin.top, margin.top],
				domain: [0, max(data, getY) ?? 0],
				nice: true,
			}),
		[margin.top, data, innerHeight],
	)

	// tooltip handler
	const handleTooltip = useCallback(
		(event: TouchEvent<SVGRectElement> | MouseEvent<SVGRectElement>) => {
			const { x } = localPoint(event) || { x: 0 }
			const x0 = xScale.invert(x)
			const index = bisectDate(data, x0, 1)
			const d0 = data[index - 1]
			const d1 = data[index]

			if (!d1) return

			let d = d0
			if (d1 && getDate(d1)) {
				d =
					x0.valueOf() - getDate(d0).valueOf() >
					getDate(d1).valueOf() - x0.valueOf()
						? d1
						: d0
			}
			showTooltip({
				tooltipData: d,
				tooltipLeft: xScale(getDate(d)),
				tooltipTop: yScale(getY(d)),
			})
			onTooltip?.(d)
		},
		[xScale, data, showTooltip, yScale, onTooltip],
	)

	// Format values to according to unit
	const formatEnergyValueAccordingToUnit = function (value: number) {
		let energyValue = value

		// Convert to correct unit
		if (valueUnit === "wh") energyValue = value * 1000
		if (valueUnit === "mwh") energyValue = value / 1000

		// Allow 2 decimal places
		energyValue = Number(energyValue.toFixed(2))

		// Format number based on locale (either . or , seperator)
		return formatNumber(energyValue)
	}

	// what is this? this is to calculate the width of the
	// see inside ./cumulative-production-graph for a bigger detail
	const dataBarWidth = useMemo(() => {
		const indexOfLastData = indexOfLastMatchInArray(data, 0)

		// if there are no 0 matches, then just return the full width
		// or if there is a 0 match, then return the width of the data graph
		if (indexOfLastData < 1) return innerWidth
		// if there is a 0 value at end, return full width
		if (indexOfLastData === data.length - 1) return innerWidth

		const divisor = data.length - 1
		// -1 since we want the one before, since indexOf gives us
		// the first 0 item
		const dividend = indexOfLastData - 1

		return (dividend / divisor) * innerWidth
	}, [data, innerWidth])

	return (
		<div>
			<svg width={width} height={height}>
				<LinearGradient
					id={getId("area-gradient")}
					from={accentColor}
					to={accentColor}
					toOpacity={0.5}
				/>
				<g clipPath={`url(#${getId("mask")})`}>
					<AreaClosed<TooltipData>
						data={data}
						x={(d) => xScale(getDate(d)) ?? 0}
						y={(d) => yScale(getY(d)) ?? 0}
						yScale={yScale}
						strokeWidth={1}
						stroke={`url(#${getId("area-gradient")})`}
						fill={`url(#${getId("area-gradient")})`}
						curve={curveMonotoneX}
						width={
							variant === "truncated"
								? innerWidth - END_GAP
								: innerWidth
						}
					/>
				</g>
				<Bar
					x={margin.left}
					y={margin.top}
					width={innerWidth}
					height={innerHeight}
					fill="transparent"
					rx={14}
					onTouchStart={displayTooltip ? handleTooltip : undefined}
					onTouchMove={displayTooltip ? handleTooltip : undefined}
					onMouseMove={displayTooltip ? handleTooltip : undefined}
					onMouseLeave={displayTooltip ? hideTooltip : undefined}
				/>
				<clipPath id={getId("mask")}>
					<motion.rect
						x={margin.left}
						y={margin.top}
						height={innerHeight}
						fill="white"
						initial={{ width: 0 }}
						animate={{
							// if there is less data than estimation data, we only animate the mask
							// up to the last point
							width: dataBarWidth,
						}}
						transition={{ duration: 2 }}
					/>
				</clipPath>
				{variant === "full" ? (
					<RegularAxis
						xScale={xScale}
						margin={margin}
						innerHeight={innerHeight}
						innerWidth={innerWidth}
						sunrise={sunrise}
						sunset={sunset}
					/>
				) : (
					<TruncatedAxis
						xScale={xScale}
						margin={margin}
						innerHeight={innerHeight}
						innerWidth={innerWidth}
						sunset={sunset}
						sunrise={sunrise}
						lastValue={lastValue || sunrise}
					/>
				)}
				<AnimatePresence>
					{tooltipData &&
						tooltipLeft !== undefined &&
						(tooltipLeft ?? 0) > 0 &&
						tooltipTop !== undefined &&
						(tooltipTop ?? 0) > 0 && (
							<motion.svg
								initial={{ opacity: 0 }}
								exit={{ opacity: 0 }}
								animate={{ opacity: 1 }}
							>
								<LinearGradient
									id={getId("area-gradient-line")}
									from="#000"
									to="#000"
									toOpacity={0}
									fromOpacity={1}
								/>
								<TooltipLine
									x={tooltipLeft}
									y={tooltipTop}
									width={4}
									height={
										innerHeight - tooltipTop + margin.top
									}
									fill={`url(#${getId(
										"area-gradient-line",
									)})`}
								>
									<TooltipPoint
										x={tooltipLeft}
										y={tooltipTop}
										fill="#000"
									/>
								</TooltipLine>
							</motion.svg>
						)}
				</AnimatePresence>
				{!isNil(totalValue) && (
					<motion.g
						initial={{ y: innerHeight / 2 + 4, opacity: 0 }}
						animate={{ y: innerHeight / 2, opacity: 1 }}
						style={{
							fontSize: 40,
							lineHeight: 40,
							fontFamily: "Static, sans-serif",
							width: innerWidth,
						}}
						transition={{ delay: 0.9, duration: 0.2 }}
					>
						<motion.text>
							<tspan
								x={innerWidth / 2}
								dominantBaseline="middle"
								textAnchor="middle"
							>
								{formatEnergyValueAccordingToUnit(
									Number(totalValue),
								)}
							</tspan>
						</motion.text>
						<motion.text
							textAnchor="middle"
							y={30}
							style={{
								fontSize: 18,
								lineHeight: 40,
								fontFamily: "Flexo, sans-serif",
							}}
							transition={{ delay: 1.1, duration: 0.2 }}
						>
							<tspan
								x={innerWidth / 2}
								dominantBaseline="middle"
								textAnchor="middle"
							>
								{t(
									`common.realtime_production.graph.${valueUnit}.label`,
								)}
							</tspan>
						</motion.text>
					</motion.g>
				)}
				<Glyph left={0} top={innerHeight + margin.top - 40 - 20}>
					<WiSunrise
						size={40}
						color="#4e5155"
						style={{ pointerEvents: "none" }}
					/>
				</Glyph>
				<Glyph
					left={innerWidth + margin.left + margin.right - 40}
					top={innerHeight + margin.top - 40 - 20}
				>
					<WiSunset
						size={40}
						color="#4e5155"
						style={{ pointerEvents: "none", background: "red" }}
					/>
				</Glyph>
			</svg>
			{displayTooltip ? (
				<AnimatePresence>
					{tooltipData && (
						<Tooltip
							left={tooltipLeft}
							top={tooltipTop}
							offsetLeft={10}
							offsetTop={10}
						>
							<div className="px-4 py-3">
								<Heading
									as="h3"
									styleAs="h5"
									className="text-black"
								>
									{`
										${formatEnergyValueAccordingToUnit(getY(tooltipData))} ${t(
										`common.realtime_production.graph.${valueUnit}.label`,
									)}
								`}
								</Heading>
								<Typography className="grid grid-cols-[10px_auto_1fr] gap-x-3 gap-y-1 text-black md:mt-1">
									{formatDate(getDate(tooltipData))}
								</Typography>
							</div>
						</Tooltip>
					)}
				</AnimatePresence>
			) : null}
		</div>
	)
}

const dateTimeFormat = new Intl.DateTimeFormat("en", { month: "short" })

const TickComponent = () => null

const tickFormat = (v: number | Date | unknown) => {
	const d = new Date(String(v))
	return dateTimeFormat.format(d)
}

function RegularAxis({
	margin,
	innerHeight,
	innerWidth,
	xScale,
	sunset,
	sunrise,
}: {
	margin: Margin
	innerHeight: number
	innerWidth: number
	sunset: string | number
	sunrise: string | number
	xScale: ScaleTime<number, number, never>
}) {
	return (
		<>
			<Text
				x={margin.left}
				y={innerHeight + margin.top + 14}
				width={200}
				verticalAnchor="start"
				style={{
					fontFamily: "Static, sans-serif",
				}}
			>
				{sunrise}
			</Text>
			<Text
				x={innerWidth + margin.right}
				y={innerHeight + margin.top + 14}
				width={200}
				verticalAnchor="start"
				textAnchor="end"
				style={{
					fontFamily: "Static, sans-serif",
				}}
			>
				{sunset}
			</Text>
			<AxisBottom
				top={innerHeight + margin.top}
				orientation={Orientation.bottom}
				tickFormat={tickFormat}
				scale={xScale}
				hideZero
				tickComponent={TickComponent}
				hideTicks
			/>
			<circle
				r={4}
				cx={margin.left}
				cy={innerHeight + margin.top}
				fill="#000"
			/>
			<circle
				r={4}
				cx={innerWidth + margin.right}
				cy={innerHeight + margin.top}
				fill="#000"
			/>
		</>
	)
}

function TruncatedAxis({
	margin,
	innerHeight,
	innerWidth,
	xScale,
	sunrise,
	sunset,
	lastValue,
}: {
	margin: Margin
	innerHeight: number
	innerWidth: number
	sunrise: string | number
	sunset: string | number
	lastValue: string | number
	xScale: ScaleTime<number, number, never>
}) {
	return (
		<>
			<Text
				x={margin.left}
				y={innerHeight + margin.top + 14}
				width={200}
				verticalAnchor="start"
				style={{
					fontFamily: "Static, sans-serif",
				}}
			>
				{sunrise}
			</Text>
			<Text
				x={innerWidth + margin.right - END_GAP}
				y={innerHeight + margin.top + 14}
				width={200}
				verticalAnchor="start"
				textAnchor="end"
				style={{
					fontFamily: "Static, sans-serif",
				}}
			>
				{lastValue}
			</Text>
			<Text
				x={innerWidth + margin.right}
				y={innerHeight + margin.top + 14}
				width={200}
				verticalAnchor="start"
				textAnchor="end"
				style={{
					fontFamily: "Static, sans-serif",
				}}
			>
				{sunset}
			</Text>
			<AxisBottom
				top={innerHeight + margin.top}
				orientation={Orientation.bottom}
				tickFormat={tickFormat}
				scale={xScale}
				hideZero
				tickComponent={TickComponent}
				hideTicks
			/>
			<line
				x1={innerWidth - END_GAP + margin.left}
				y1={innerHeight + margin.top}
				x2={innerWidth + margin.left}
				y2={innerHeight + margin.top}
				strokeWidth={1}
				stroke="#000"
				strokeDasharray="2,2"
			/>
			<circle
				r={4}
				cx={margin.left}
				cy={innerHeight + margin.top}
				fill="#000"
			/>
			<circle
				r={4}
				cx={innerWidth + margin.right - END_GAP}
				cy={innerHeight + margin.top}
				fill="#000"
			/>
			<circle
				r={4}
				cx={innerWidth + margin.right}
				cy={innerHeight + margin.top}
				fill="#000"
			/>
		</>
	)
}
