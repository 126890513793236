export const featureFlags: { name: string; value: boolean }[] = [
	// Graphs
	{ name: "FISCAL_OVERVIEW_BY_YEAR_DISPLAY_BUTTONS", value: false },
	{ name: "FEATURE_ENABLE_FISCAL_OVERVIEW_DATE_SELECTOR", value: false },
	{ name: "PROJECT_DISPLAY_DATE_SELECTOR", value: false },

	// Energy supplier partnerships
	{ name: "PROJECT_DISPLAY_ENERGY_SUPPLIER_LINK", value: false },

	// Communication hub
	{ name: "PROJECT_DISPLAY_MESSAGE_COUNT", value: false },

	// Account MFA
	{ name: "ENABLE_MFA_SECURITY_PAGE", value: false },

	// Mobile UX
	{ name: "ENABLE_MENU_COLLAPSE_ON_DESKTOP", value: false },

	// Stats
	{ name: "ENABLE_STATS_MY_SAVINGS", value: false },
	{ name: "ENABLE_STATS_ADDITIONAL", value: false },
	{
		name: "ENABLE_PROJECT_DASHBOARD_PRODUCTION_LIVE_PERFORMANCE",
		value: false,
	},
	{ name: "ENABLE_EXTENDED_PROJECT_DASHBOARD", value: false },

	// Project timeline
	{ name: "FEATURE_ENABLE_PROJECT_TIMELINE", value: false },

	// Investor profile
	{
		name: "FEATURE_ENABLE_INVESTOR_FUND_ORIGINS",
		value: false,
	},

	{ name: "ENABLE_PROJECT_OWNER_LOAN_INFO", value: false },
]
