// React
import { ChangeEvent } from "react"

// UI
import { Heading } from "@/components/Typography"
import { CardBody, CardWrapper } from "@/components/Card"
import { Button } from "@/components/Button"
import { Select } from "@/components/form-controls"
import { Label } from "@/components/form-controls"
import { FormGroup } from "@/components/form-controls"
import { LabelSubtitle } from "@/components/form-controls"

// State
import { useDispatch, useSelector } from "@/state/StateProvider"
import { UserPreferencesState } from "@/state/features/userPreferencesSlice"
import {
	setPerPage,
	setFilterState,
	setFilterType,
	setSortingMethod,
	setSortingOrder,
	setViewType,
} from "@/state/features/investmentsOverview/slice"

// Types
import {
	InvestmentsOverviewState,
	ProjectPageViewTypes,
	projectPageViewTypes,
	projectSortMethod,
	ProjectSortOrder,
	projectPageSizes,
	ProjectSortMethod,
	ProjectPageSize,
	ProjectStateEnumFiltered,
	ProjectTypeEnumFiltered,
	ALL_PROJECT_STATES,
	ALL_PROJECT_TYPES,
} from "@/state/features/investmentsOverview/types"

// Icons
import { FiChevronDown } from "@/lib/icons"

// Translations
import { useLang } from "@/context/lang"
import { Languages, configs } from "@/i18n/config"
import { useTrans } from "@/i18n"

/**
 * AppSettings
 * @returns
 */
export const AppSettings = () => {
	const t = useTrans(["profile", "investments"])
	const dispatch = useDispatch()
	const { setLang } = useLang()

	// State
	const userPreferences = useSelector(
		({ userPreferences }: { userPreferences: UserPreferencesState }) =>
			userPreferences,
	)
	const investmentsOverview = useSelector(
		({
			investmentsOverview,
		}: {
			investmentsOverview: InvestmentsOverviewState
		}) => investmentsOverview,
	)

	return (
		<div className="space-y-6 md:space-y-8 lg:space-y-12">
			<CardWrapper>
				<CardBody>
					<Heading as="h2" styleAs="h5" className="mb-3 sm:truncate">
						{t("profile.settings.app")}
					</Heading>
					<FormGroup
						heading={<>{t("profile.settings.app.description")}</>}
					>
						<div
							className="w-fulls grid gap-6 2xl:grid-cols-2"
							data-testid="form-group"
						>
							{/** Per page */}
							<div className="2xl:col-span-2">
								<Label className="flex">
									<span className="flex items-center">
										{t(
											"profile:profile.settings.app.setting.investments-per-page.label",
										)}
									</span>
								</Label>
								<div className="relative lg:w-1/2">
									<DropDownComponent
										selectedValueText={t(
											"investments:investments.pagination.set_page_size",
											{
												count: investmentsOverview.perPage,
											},
										)}
										value={investmentsOverview.perPage}
										options={projectPageSizes}
										onChange={(
											event: ChangeEvent<HTMLSelectElement>,
										) => {
											dispatch(
												setPerPage(
													Number(
														event.currentTarget
															.value,
													) as ProjectPageSize,
												),
											)
										}}
									/>
								</div>
							</div>

							{/** Project sorting */}
							<div className="2xl:col-span-2">
								<Label className="flex">
									<span className="flex items-center">
										{t(
											"profile.settings.app.setting.investments-sorting.label",
										)}
									</span>
								</Label>
								<div className="relative lg:w-1/2">
									<DropDownComponent
										selectedValueText={t(
											`investments:investments.sort_method.${investmentsOverview.sortingMethod}`,
										)}
										value={
											investmentsOverview.sortingMethod
										}
										options={projectSortMethod}
										onChange={(
											event: ChangeEvent<HTMLSelectElement>,
										) => {
											dispatch(
												setSortingMethod(
													event.currentTarget
														.value as ProjectSortMethod,
												),
												setSortingOrder(
													ProjectSortOrder.Asc,
												),
											)
										}}
									/>
								</div>
							</div>

							{/** Project filter */}
							<div className="2xl:col-span-2">
								<div className="relative z-20">
									<Label className="flex">
										<span className="flex items-center">
											{t(
												"profile.settings.app.setting.investments-types.label",
											)}
										</span>
									</Label>
									<div className="relative lg:w-1/2">
										<DropDownComponent
											selectedValueText={
												investmentsOverview.filterType.toUpperCase() ===
												ALL_PROJECT_TYPES
													? t(
															"investments:investments.filter.all",
													  )
													: t(
															`common:common.project.type.${investmentsOverview.filterType}`,
													  )
											}
											value={
												investmentsOverview.filterType
											}
											options={Object.values(
												ProjectTypeEnumFiltered,
											)}
											onChange={(
												event: ChangeEvent<HTMLSelectElement>,
											) => {
												dispatch(
													setFilterType(
														event.currentTarget
															.value as ProjectTypeEnumFiltered,
													),
												)
											}}
										/>
									</div>
								</div>
							</div>

							{/** Filter status */}
							<div className="2xl:col-span-2">
								<Label className="flex">
									<span className="flex items-center">
										{t(
											"profile.settings.app.setting.investments-state-filter.label",
										)}
									</span>
								</Label>
								<div className="relative lg:w-1/2">
									<DropDownComponent
										selectedValueText={
											investmentsOverview.filterState.toUpperCase() ===
											ALL_PROJECT_STATES
												? t(
														"investments:investments.filter.all",
												  )
												: t(
														`common:common.project.status.${investmentsOverview.filterState}`,
												  )
										}
										value={investmentsOverview.filterState}
										options={Object.values(
											ProjectStateEnumFiltered,
										)}
										onChange={(
											event: ChangeEvent<HTMLSelectElement>,
										) => {
											dispatch(
												setFilterState(
													event.currentTarget
														.value as ProjectStateEnumFiltered,
												),
											)
										}}
									/>
								</div>
							</div>

							{/** Project overview view type */}
							<div className="2xl:col-span-2">
								<Label className="flex">
									<span className="flex items-center">
										{t(
											"profile.settings.app.setting.investments-view-type.label",
										)}
									</span>
								</Label>
								<div className="relative lg:w-1/2">
									<DropDownComponent
										selectedValueText={t(
											`common:common.project.view.${investmentsOverview.viewType.toUpperCase()}`,
										)}
										value={investmentsOverview.viewType}
										options={projectPageViewTypes}
										onChange={(
											event: ChangeEvent<HTMLSelectElement>,
										) => {
											dispatch(
												setViewType(
													event.currentTarget
														.value as ProjectPageViewTypes,
												),
											)
										}}
									/>
								</div>
							</div>

							{/** Language selector */}
							<div className="sm:border-t sm:border-gray-200 sm:pt-5 2xl:col-span-2">
								<Label>
									{t(
										"profile.settings.app.setting.preferred-language.label",
									)}
									<LabelSubtitle>
										{t(
											"profile.settings.app.setting.preferred-language.sublabel",
										)}
									</LabelSubtitle>
								</Label>
								<div className="relative lg:w-1/2">
									<DropDownComponent
										selectedValueText={`${userPreferences.lang}`}
										options={Object.values(configs).map(
											(config) => config.path,
										)}
										value={userPreferences.lang}
										onChange={(
											event: ChangeEvent<HTMLSelectElement>,
										) => {
											setLang(
												event.currentTarget
													.value as Languages,
											)
										}}
									/>
								</div>
							</div>
						</div>
					</FormGroup>
				</CardBody>
			</CardWrapper>
		</div>
	)
}

interface DropDownComponentProps {
	selectedValueText: String
	value: string | number
	onChange: Function
	options: any[]
}
const DropDownComponent = ({
	selectedValueText,
	value,
	onChange,
	options = [],
}: DropDownComponentProps) => {
	return (
		<div className="relative">
			<label>
				<Button size="small" variant="transparent" className="w-full">
					{selectedValueText}
					<FiChevronDown className={"ml-2"} />
					<Select
						name="pageSize"
						className="h-100 absolute left-0 top-0 w-full cursor-pointer opacity-0"
						onChange={(event) => onChange(event)}
						value={value ?? undefined}
					>
						{options.map((item) => (
							<option value={item} key={item}>
								{item}
							</option>
						))}
					</Select>
				</Button>
			</label>
		</div>
	)
}
