import { QueryClient } from "@tanstack/react-query"

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			suspense: true,
			staleTime: 5 * 60 * 1000,
			cacheTime: 10 * 60 * 1000,
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			retry: 1,
		},
	},
})

export { QueryClientProvider, QueryClient } from "@tanstack/react-query"
