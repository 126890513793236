import { useState, useEffect } from "react"
import { Helmet } from "@/lib/seo"

// Navigation
import { Pages } from "@/misc/pages"
import { useNavigate } from "react-router-dom"

// Translations
import { useLang } from "@/context/lang"
import { useTrans } from "@/i18n"

// UI
import { Button } from "@/components/Button"
import { Heading } from "@/components/Typography"
import { CardBody, CardWrapper, Card } from "@/components/Card"
import { TableWarning } from "@/components/table-controls/TableWarning"

// Forms
import { FormikProvider, useFormik } from "formik"
import * as Yup from "yup"
import {
	FormikRadio,
	FormikCheckbox,
	FormikSubmitButton,
} from "@/components/form-controls/formik"

// GraphQL
import {
	CoreInvestmentTestCategoryChoices,
	useInvestorTestMutationsInputMutation,
	InvestmentTestType,
} from "@/api/graphql"
import { useToasts } from "@/context/toasts"

// DateTime
import { DateTime } from "@/lib/dates"
import { dateFormat } from "@/misc/constants"

// Icons
import { CheckIcon } from "@heroicons/react/outline"

// Context
import { useInvestmentTests } from "@/context/investmentTests"
import { useHiddenGetParamsContext } from "@/context/hiddenGetParams"
import { useCurrentUserId } from "@/context/user"

// Environment variables
import { API_URL } from "@/lib/env"

interface TestAnswers {
	[key: number]: string
}

interface QuestionChoice {
	title: string
	isCorrectChoice: boolean
}
type QuestionFeedbackType = "answer" | "advice" | "comment"
interface Question {
	id: number
	title: string
	feedbackString?: string
	feedbackTypes: QuestionFeedbackType[]
	choices: QuestionChoice[]
}

/**
 * InvestorTest
 * @returns
 */
export const InvestorTest = () => {
	const toasts = useToasts()
	const navigate = useNavigate()

	// Context
	const { investorTestQuestions, investorTestRisk, refetch } =
		useInvestmentTests()
	const { next } = useHiddenGetParamsContext()
	const { id: userId } = useCurrentUserId()

	// State
	const [showLanguageDisclaimer, setShowLanguageDisclaimer] =
		useState<boolean>(false)

	// Translations
	const t = useTrans("investor")
	const { lang, setLang } = useLang()

	// Hack to always reset to lang=NL on this page because we don't have the legal english texts yet
	useEffect(() => {
		if (lang !== "nl") {
			setShowLanguageDisclaimer(true)
			setLang("nl")
		}
	}, [lang, setLang])

	// What questions to ask?
	const questions: Question[] = [
		{
			id: 1,
			title: t("investor:investor.test.form.fields.q1.title"),
			feedbackString: t("investor:investor.test.form.fields.q1.feedback"),
			feedbackTypes: ["advice"],
			choices: [
				{
					title: t(
						"investor:investor.test.form.fields.q1.answer-correct",
					),
					isCorrectChoice: true,
				},
				{
					title: t(
						"investor:investor.test.form.fields.q1.answer-wrong",
					),
					isCorrectChoice: false,
				},
			],
		},
		{
			id: 2,
			title: t("investor:investor.test.form.fields.q2.title"),
			feedbackString: t("investor:investor.test.form.fields.q2.feedback"),
			feedbackTypes: ["advice"],
			choices: [
				{
					title: t(
						"investor:investor.test.form.fields.q2.answer-correct",
					),
					isCorrectChoice: true,
				},
				{
					title: t(
						"investor:investor.test.form.fields.q2.answer-wrong",
					),
					isCorrectChoice: false,
				},
			],
		},
		{
			id: 3,
			title: t("investor:investor.test.form.fields.q3.title"),
			feedbackString: t("investor:investor.test.form.fields.q3.feedback"),
			feedbackTypes: ["advice"],
			choices: [
				{
					title: t(
						"investor:investor.test.form.fields.q3.answer-wrong",
					),
					isCorrectChoice: false,
				},
				{
					title: t(
						"investor:investor.test.form.fields.q3.answer-correct",
					),
					isCorrectChoice: true,
				},
			],
		},
		{
			id: 4,
			title: t("investor:investor.test.form.fields.q4.title"),
			feedbackString: t("investor:investor.test.form.fields.q4.feedback"),
			feedbackTypes: ["advice"],
			choices: [
				{
					title: t(
						"investor:investor.test.form.fields.q4.answer-wrong",
					),
					isCorrectChoice: false,
				},
				{
					title: t(
						"investor:investor.test.form.fields.q4.answer-correct",
					),
					isCorrectChoice: true,
				},
			],
		},
		{
			id: 5,
			title: t("investor:investor.test.form.fields.q5.title"),
			feedbackString: t("investor:investor.test.form.fields.q5.feedback"),
			feedbackTypes: ["advice"],
			choices: [
				{
					title: t(
						"investor:investor.test.form.fields.q5.answer-wrong",
					),
					isCorrectChoice: false,
				},
				{
					title: t(
						"investor:investor.test.form.fields.q5.answer-correct",
					),
					isCorrectChoice: true,
				},
			],
		},
		{
			id: 6,
			title: t("investor:investor.test.form.fields.q6.title"),
			feedbackString: t("investor:investor.test.form.fields.q6.feedback"),
			feedbackTypes: ["advice"],
			choices: [
				{
					title: t(
						"investor:investor.test.form.fields.q6.answer-wrong",
					),
					isCorrectChoice: false,
				},
				{
					title: t(
						"investor:investor.test.form.fields.q6.answer-correct",
					),
					isCorrectChoice: true,
				},
			],
		},
		{
			id: 7,
			title: t("investor:investor.test.form.fields.q7.title"),
			feedbackTypes: ["answer"],
			choices: [
				{
					title: t(
						"investor:investor.test.form.fields.q7.answer-wrong",
					),
					isCorrectChoice: false,
				},
				{
					title: t(
						"investor:investor.test.form.fields.q7.answer-correct",
					),
					isCorrectChoice: true,
				},
			],
		},
		{
			id: 8,
			title: t("investor:investor.test.form.fields.q8.title"),
			feedbackTypes: ["answer"],
			choices: [
				{
					title: t(
						"investor:investor.test.form.fields.q8.answer-wrong",
					),
					isCorrectChoice: false,
				},
				{
					title: t(
						"investor:investor.test.form.fields.q8.answer-correct",
					),
					isCorrectChoice: true,
				},
			],
		},
		{
			id: 9,
			title: t("investor:investor.test.form.fields.q9.title"),
			feedbackString: t("investor:investor.test.form.fields.q9.feedback"),
			feedbackTypes: ["answer", "comment"],
			choices: [
				{
					title: t(
						"investor:investor.test.form.fields.q9.answer-wrong",
					),
					isCorrectChoice: false,
				},
				{
					title: t(
						"investor:investor.test.form.fields.q9.answer-correct",
					),
					isCorrectChoice: true,
				},
			],
		},
	]

	// State
	const [testAnswers, setTestAnswers] = useState<TestAnswers>()
	const [badlyAnsweredQuestions, setBadlyAnsweredQuestions] = useState<
		Question[]
	>([])

	// Form
	const form = useFormik({
		initialValues: {},
		validationSchema: Yup.object().shape({
			1: Yup.bool().required(),
			2: Yup.bool().required(),
			3: Yup.bool().required(),
			4: Yup.bool().required(),
			5: Yup.bool().required(),
			6: Yup.bool().required(),
			7: Yup.bool().required(),
			8: Yup.bool().required(),
			9: Yup.bool().required(),
		}),
		onSubmit: async (values) => {
			setTestAnswers(values)
		},
	})

	// Forms
	const formAcceptTerms = useFormik({
		initialValues: {
			acceptedTerms: false,
		},
		validationSchema: Yup.object().shape({
			acceptedTerms: Yup.bool().oneOf([true]),
		}),
		onSubmit: async () => {
			await createNewTestSubmission.mutateAsync({
				input: {
					investor: userId || "",
					category: CoreInvestmentTestCategoryChoices.Questions,
				},
			})
		},
	})

	// Check which questions are answered badly once testAnswers are saved in state
	useEffect(() => {
		const badlyAnsweredQuestions = questions.filter(
			(question: Question) => {
				if (testAnswers) {
					const answer = testAnswers[question.id]
					return answer === "false" // Radio button values are stored as strings instead of booleans
				}
				return false
			},
		)
		setBadlyAnsweredQuestions(badlyAnsweredQuestions)
	}, [testAnswers])

	// Mutation
	const createNewTestSubmission = useInvestorTestMutationsInputMutation({
		onSuccess: async (response) => {
			// do we have data? then success
			if (response?.investment_test_update?.investor_test?.id) {
				toasts.addToast({
					variant: "success",
					id: `notifications-success-${Date.now()}`,
					text: t("investor:investor.test.form.alerts.success"),
				})

				// Refetch data
				refetch()

				// Scroll to top
				window.scrollTo(0, 0)

				// When 'next' param is set in context, redirect there in the API
				if (next && Boolean(investorTestRisk) === true) {
					window.location.href = `${API_URL}${next}`
				}
			}
		},
	})

	return (
		<>
			<Helmet>
				<title>{t("investor:investor.test.title")}</title>
			</Helmet>

			<CardWrapper className="relative">
				{Boolean(investorTestQuestions) === true && (
					<StepCompletedOverlay
						onClickButton={() => navigate(Pages.InvestorRisk)}
						investorTestQuestions={
							investorTestQuestions as InvestmentTestType
						}
					/>
				)}

				{/** Ask questions when not yet filled in */}
				{testAnswers === undefined && (
					<CardBody>
						<Heading
							as="h2"
							styleAs="h5"
							className="mb-3 sm:truncate"
						>
							{t("investor:investor.test.heading")}
						</Heading>

						{showLanguageDisclaimer === true && (
							<TableWarning
								className="mb-3"
								title={t(
									"investor:investor.generic.legal-disclaimer.dutch.title",
								)}
								message={t(
									"investor:investor.generic.legal-disclaimer-dutch.message",
								)}
							/>
						)}

						<div className="flex">
							<div className="w-full lg:w-2/3">
								<FormikProvider value={form}>
									<form
										onSubmit={form.handleSubmit}
										className="ml-4"
									>
										<ol className="ml-0 list-decimal">
											{questions.map((question) => (
												<li
													className="mb-8"
													key={String(question.id)}
												>
													<label className="mb-2 block">
														{question.title}
													</label>
													{question.choices.map(
														(choice) => (
															<div
																className="flex"
																key={
																	choice.title
																}
															>
																<FormikRadio
																	name={String(
																		question.id,
																	)}
																	value={String(
																		choice.isCorrectChoice,
																	)}
																	id={String(
																		`${question.id}-${choice.isCorrectChoice}`,
																	)}
																	className="mr-2"
																/>
																<label
																	htmlFor={String(
																		`${question.id}-${choice.isCorrectChoice}`,
																	)}
																>
																	{
																		choice.title
																	}
																</label>
															</div>
														),
													)}
												</li>
											))}
										</ol>

										<FormikSubmitButton
											disableWhenPristine={true}
										>
											{t(
												"investor:investor.test.form.buttons.submit",
											)}
										</FormikSubmitButton>
									</form>
								</FormikProvider>
							</div>
						</div>

						{/** Continue investment flow */}
						{next && (
							<TableWarning
								className={"mt-4"}
								message="Je investeerdersprofiel is bijna compleet. Nadat de stappen zijn afgerond kun je doorgaan met je investering."
								title={""}
							/>
						)}
					</CardBody>
				)}

				{/** When questions have been answered */}
				{testAnswers !== undefined && (
					<CardBody>
						<Heading
							as="h2"
							styleAs="h5"
							className="mb-3 sm:truncate"
						>
							{t("investor:investor.test.feedback.heading")}
						</Heading>
						<div className="flex flex-col gap-4 md:gap-8 lg:flex-row">
							<div className="w-full lg:w-1/2">
								<p className="mb-6">
									{badlyAnsweredQuestions.length > 0 ? (
										<>
											{t(
												"investor:investor.test.feedback.wrong.copy",
											)}
										</>
									) : (
										<>
											{t(
												"investor:investor.test.feedback.correct.copy",
											)}
										</>
									)}
								</p>

								{badlyAnsweredQuestions.length > 0 && (
									<>
										<span className="font-bold">
											{t(
												"investor:investor.test.feedback.wrong.title",
											)}
										</span>
										{badlyAnsweredQuestions.map(
											(question) => (
												<div
													className="mb-4 rounded-md border border-gray-300 bg-gray-100 p-4"
													key={question.id}
												>
													<div className="mb-5">
														{`${question.id}. ${question.title}`}
													</div>

													{/** Show users answer (which is always the BAD answer because it is inside badlyAnsweredQuestions) */}
													<div>
														<span className="mr-1 font-bold">
															{t(
																"investor:investor.test.feedback.wrong.suggest-answer",
															)}
														</span>
														{
															question.choices.find(
																(choice) =>
																	choice.isCorrectChoice ===
																	false,
															)?.title
														}
													</div>
													{/** Advice */}
													{question.feedbackTypes.includes(
														"advice",
													) && (
														<div>
															<span className="mr-1 font-bold">
																{t(
																	"investor:investor.test.feedback.wrong.suggest-advice",
																)}
															</span>
															{
																question.feedbackString
															}
														</div>
													)}

													{/** Correct answer */}
													{question.feedbackTypes.includes(
														"answer",
													) && (
														<div>
															<span className="mr-1 font-bold">
																{t(
																	"investor:investor.test.feedback.wrong.suggest-correct",
																)}
															</span>
															{
																question.choices.find(
																	(choice) =>
																		choice.isCorrectChoice ===
																		true,
																)?.title
															}
														</div>
													)}

													{/** Comment */}
													{question.feedbackTypes.includes(
														"comment",
													) && (
														<div>
															{
																question.feedbackString
															}
														</div>
													)}
												</div>
											),
										)}
									</>
								)}

								<FormikProvider value={formAcceptTerms}>
									<form
										onSubmit={formAcceptTerms.handleSubmit}
									>
										<div className="flex">
											<FormikCheckbox
												className="mr-2"
												name="acceptedTerms"
											/>
											<label htmlFor="acceptedTerms">
												{t(
													"investor:investor.test.feedback.form.terms",
												)}
											</label>
										</div>
										<FormikSubmitButton className="mt-4">
											{t(
												"investor:investor.test.feedback.form.buttons.submit",
											)}
										</FormikSubmitButton>
									</form>
								</FormikProvider>
							</div>
							<div className="w-full lg:w-1/2">
								<div>
									{badlyAnsweredQuestions.length > 0 ? (
										<>
											{t(
												"investor:investor.test.feedback.wrong.suggest-tips",
											)}
										</>
									) : (
										<>
											{t(
												"investor:investor.test.feedback.correct.suggest-tips",
											)}
										</>
									)}
									{t(
										"investor:investor.test.feedback.tips.copy",
									)}
									<div className="ml-4">
										<ul className="ml-0 list-disc">
											<li>
												{t(
													"investor:investor.test.feedback.tips.list.item1",
												)}
											</li>
											<li>
												{t(
													"investor:investor.test.feedback.tips.list.item2",
												)}
											</li>
											<li>
												{t(
													"investor:investor.test.feedback.tips.list.item3",
												)}
											</li>
											<li>
												{t(
													"investor:investor.test.feedback.tips.list.item4",
												)}
											</li>
										</ul>
									</div>
									<p className="mt-4">
										{t(
											"investor:investor.test.feedback.tips.read-more",
										)}
									</p>
								</div>
							</div>
						</div>

						{/** Continue investment flow */}
						{next && (
							<TableWarning
								className={"mt-4"}
								message="Je investeerdersprofiel is bijna compleet. Nadat de stappen zijn afgerond kun je doorgaan met je investering."
								title={""}
							/>
						)}
					</CardBody>
				)}
			</CardWrapper>
		</>
	)
}

const StepCompletedOverlay = ({
	onClickButton,
	investorTestQuestions,
}: {
	onClickButton: () => void
	investorTestQuestions: InvestmentTestType
}) => {
	// Context
	const { amountOfRequiredActionsLeft } = useInvestmentTests()

	return (
		<div className="absolute inset-0 z-10 bg-gray-600 bg-opacity-50 p-5 text-center text-lg">
			<Card className="mx-auto max-w-md">
				<div className="space-y-4 text-center">
					<Heading
						as="h2"
						styleAs="h5"
						className="flex justify-center gap-2"
					>
						<div className="flex items-center">
							<CheckIcon
								aria-hidden="true"
								className="h-5 w-5 text-lg text-green-500"
							/>
						</div>
						Voltooid op{" "}
						{DateTime.fromISO(
							investorTestQuestions?.created_at,
						).toFormat(dateFormat)}
					</Heading>

					{amountOfRequiredActionsLeft !== undefined &&
						amountOfRequiredActionsLeft !== 0 && (
							<Button onClick={() => onClickButton()}>
								Volgende stap
							</Button>
						)}
				</div>
			</Card>
		</div>
	)
}
