import { Fragment } from "react"
import { DateTime } from "@/lib/dates"
import { FiArrowRight } from "react-icons/fi"
import { sendEvent } from "@/lib/analytics"

// Graphql
import { ProjectStateEnum, ProjectTypeEnum } from "@/api/graphql"

// Translations
import { useLang } from "@/context/lang"
import { Trans, useTrans } from "@/i18n"

// UI
import { classNames } from "@/lib/classnames"
import { Link } from "./Anchor"
import { PillInverted } from "./Pill"
import { Heading } from "./Typography"
import { LazyLoadImage } from "../components/LazyLoadImage"
import { Checkbox } from "@/components/form-controls/Checkbox"

// Images
import piggy_bank from "@/assets/icons/piggy_bank.min-cropped.svg"
import power from "@/assets/icons/power.min-cropped.svg"
import salary from "@/assets/icons/salary.min-cropped.svg"
import socket from "@/assets/icons/socket.min-cropped.svg"
import envelope from "@/assets/icons/envelope.min-cropped.svg"

// Feature flags
import { useFeatureFlags } from "@/context/user"

// State
import { useDispatch, useSelector } from "@/state/StateProvider"
import { InvestmentsOverviewState } from "@/state/features/investmentsOverview/types"
import { setHiddenIds } from "@/state/features/investmentsOverview/slice"

const icons = {
	piggy_bank,
	power,
	salary,
	socket,
	envelope,
}

interface ProjectCardProps {
	id?: string | null
	projectName: string
	slug: string
	status?: ProjectStateEnum | null
	type?: ProjectTypeEnum | null
	image: string
	amountInvested: number
	energySavings: number
	energySupplier: string
	messageCount?: number
	hasEnergySupplierMessage: boolean
	className?: string
	totalRepayment: number
	comingInterestPeriod: Date | null
	analyticsContext?: string
}

/**
 * ProjectCard
 *
 * TODO: rename file
 * @param param0
 * @returns
 */
export const ProjectCard = ({
	id,
	analyticsContext,
	className,
	image,
	slug,
	comingInterestPeriod,
	amountInvested,
	energySavings,
	messageCount,
	hasEnergySupplierMessage,
	energySupplier,
	totalRepayment,
	status,
	type,
	projectName,
}: ProjectCardProps) => {
	const t = useTrans("common")
	const { formatCurrency, formatNumber, config } = useLang()
	const { getFeatureFlagValue } = useFeatureFlags()

	// State
	const dispatch = useDispatch()
	const projectId = Number(id)
	const { hiddenIds } = useSelector(
		({
			investmentsOverview,
		}: {
			investmentsOverview: InvestmentsOverviewState
		}) => investmentsOverview,
	)
	const isHidden = hiddenIds.includes(projectId)

	return (
		<div
			className={classNames(
				className,
				"overflow-hidden rounded-lg bg-white shadow-md transition duration-500 ease-in-out",
			)}
		>
			<Link
				href={`/investments/projects/${slug}/dashboard`}
				className="z-2 group relative block pb-4"
				onClick={() => {
					sendEvent("project_card", "click", {
						label: analyticsContext ?? slug,
					})
				}}
			>
				<div className={`group relative block`}>
					<LazyLoadImage
						className={`z-1 absolute h-full w-full scale-100 transform-gpu object-cover transition-transform hover:scale-110`}
						alt={projectName}
						src={image}
					/>

					{/** Type */}
					{type && (
						<div className="bg-primary-500 absolute right-2 top-2 z-10 rounded-md px-4 py-1 font-bold text-black opacity-90 shadow-2xl">
							{t(`common.project.type.${type}`)}
						</div>
					)}
				</div>

				{/** Status */}
				{status && (
					<div className="px-3">
						<PillInverted
							variant={status}
							className="inline-block -translate-y-1/2 transform"
						>
							{t(`common.project.status.${status}`)}
						</PillInverted>
						<p className="text-md font-medium">
							{projectName}
							<FiArrowRight className="ml-2 inline -translate-x-1/3 transform opacity-0 transition group-hover:translate-x-0 group-hover:opacity-100" />
						</p>
					</div>
				)}
			</Link>
			<div className="z-1 relative mt-auto px-3 pb-3">
				<div className="relative -mt-3 mb-4 flex flex-wrap">
					<div className="z-1 absolute left-1/2 top-3 mt-3 h-2/4 w-[2px] -translate-x-3 transform bg-gray-200" />
					<div className="min-w-1/2 z-2 relative mr-6 max-w-full flex-grow bg-white pt-3">
						<Heading as="h3" styleAs="h4" className="truncate">
							{t("common.project_card.value", {
								amount: formatCurrency(amountInvested),
							})}
						</Heading>
						<p className="text-sm text-gray-500">
							{t("common.project_card.value.subtitle")}
						</p>
					</div>
					{type !== "PORTFOLIO" && (
						<div className="min-w-1/2 z-2 relative max-w-full flex-grow bg-white pt-3">
							<Heading as="h3" styleAs="h4" className="truncate">
								<Trans
									ns="common"
									i18nKey="common.project_card.energy_saved"
									components={{
										icon: (
											<img
												src={icons.power}
												alt=""
												aria-hidden={true}
												className="mr-2 inline w-6 -translate-y-1 transform align-middle"
											/>
										),
									}}
									values={{
										amount: formatNumber(
											parseFloat(
												energySavings.toFixed(1),
											),
											{
												minimumFractionDigits: 1,
											},
										),
									}}
								/>
							</Heading>
							<p className="text-sm text-gray-500">
								{t("common.project_card.energy_saved.subtitle")}
							</p>
						</div>
					)}
				</div>

				<div className="grid-cols-project-card-list-small lg:grid-cols-project-card-list grid auto-rows-max items-center gap-x-4 gap-y-0 text-sm text-gray-500">
					<div>
						<img
							className="mx-auto h-8 w-6 object-contain object-center opacity-50"
							src={icons.piggy_bank}
							alt=""
							aria-hidden={true}
						/>
					</div>
					<p
						className="truncate"
						title={t("common.project_card.amount_received", {
							amount: formatCurrency(totalRepayment),
						})}
					>
						{t("common.project_card.amount_received", {
							amount: formatCurrency(totalRepayment),
						})}
					</p>
					{comingInterestPeriod ? (
						<>
							<div>
								<img
									className="mx-auto h-8 w-5 object-contain object-center opacity-50"
									src={icons.salary}
									alt=""
									aria-hidden={true}
								/>
							</div>
							<p
								className="truncate"
								title={t(
									"common.project_card.interest_period",
									{
										date: DateTime.fromJSDate(
											comingInterestPeriod,
										).toFormat(
											config.dateFormatProjectCardInterestPeriod,
										),
									},
								)}
							>
								{t("common.project_card.interest_period", {
									date: DateTime.fromJSDate(
										comingInterestPeriod,
									).toFormat(
										config.dateFormatProjectCardInterestPeriod,
									),
								})}
							</p>
						</>
					) : null}
					{getFeatureFlagValue(
						"PROJECT_DISPLAY_ENERGY_SUPPLIER_LINK",
					) === true && (
						<>
							<div className="relative">
								<img
									className="z-1 relative mx-auto h-8 w-5 object-contain object-center opacity-50"
									src={icons.socket}
									alt=""
									aria-hidden={true}
								/>
								{hasEnergySupplierMessage ? (
									<div className="z-2 bg-primary-500 absolute right-0 top-0 flex h-4 min-w-[1rem] items-center justify-center rounded-full text-center text-xs text-black">
										!
									</div>
								) : null}
							</div>
							<Link
								href="/"
								className="truncate text-gray-900 hover:text-gray-500"
								title={t(
									"common.project_card.energy_supplier",
									{
										energySupplier,
									},
								)}
								onClick={() => {
									sendEvent(
										"project_card",
										"click_energy_supplier",
										{
											label: analyticsContext ?? slug,
										},
									)
								}}
							>
								{t("common.project_card.energy_supplier", {
									energySupplier,
								})}
							</Link>
						</>
					)}
					{getFeatureFlagValue("PROJECT_DISPLAY_MESSAGE_COUNT") ===
						true && messageCount !== undefined ? (
						<>
							<div className="relative">
								<img
									className="z-1 relative mx-auto h-8 w-5 object-contain object-center opacity-50"
									src={icons.envelope}
									alt=""
									aria-hidden={true}
								/>
								{messageCount > 0 ? (
									<div className="z-2 bg-primary-500 absolute right-0 top-0 flex h-4 min-w-[1rem] items-center justify-center rounded-full text-center text-xs text-black">
										{messageCount}
									</div>
								) : null}
							</div>
							<Link
								href="/"
								className="truncate text-gray-900 hover:text-gray-500"
								title={t(
									messageCount === 0
										? "common.project_card.messages_zero"
										: "common.project_card.messages",
									{
										count: messageCount,
									},
								)}
								onClick={() => {
									sendEvent(
										"project_card",
										"click_messages",
										{
											label: analyticsContext ?? slug,
										},
									)
								}}
							>
								{t(
									messageCount === 0
										? "common.project_card.messages_zero"
										: "common.project_card.messages",
									{
										count: messageCount,
									},
								)}
							</Link>
						</>
					) : null}
				</div>
				<div className="flex w-full justify-end text-sm text-gray-500">
					<label htmlFor="hideProject" className="flex items-center">
						{t("common:common.project_card.hide")}
						<Checkbox
							id="hideProject"
							name="hideProject"
							checked={isHidden}
							onChange={() => {
								dispatch(
									setHiddenIds({
										id: projectId,
										isHidden: !isHidden,
									}),
								)
							}}
							data-testid="sign_in.remember_me"
							aria-label="rememberMe"
							className="ml-2"
						/>
					</label>
				</div>
			</div>
		</div>
	)
}

export function ProjectCardPreloader() {
	const { getFeatureFlagValue } = useFeatureFlags()

	return (
		<div className="h-auto animate-pulse overflow-hidden rounded-lg bg-white shadow-md">
			{/* image */}
			<div className="relative block bg-gray-100 pb-[60%]" />
			{/* status */}
			<div className="px-3 pb-4">
				<PillInverted className="dummy-text inline-block opacity-0" />
				<p className="text-md dummy-text w-3/4 rounded-md bg-gray-100" />
			</div>
			<div className="-mt-3 px-3 pb-3">
				<div className="mb-4 flex flex-wrap gap-y-3 pt-3">
					<div className="min-w-1/2 max-w-full flex-grow">
						<Heading
							as="h3"
							styleAs="h4"
							className="dummy-text w-3/4 rounded-t-lg bg-gray-100"
						/>
						<p className="dummy-text w-3/4 rounded-b-lg bg-gray-100 text-sm" />
					</div>
					<div className="min-w-1/2 max-w-full flex-grow">
						<Heading
							as="h3"
							styleAs="h4"
							className="dummy-text w-3/4 rounded-t-lg bg-gray-100"
						/>
						<p className="dummy-text w-3/4 rounded-b-lg bg-gray-100 text-sm" />
					</div>
				</div>
				<div className="grid-cols-project-card-list-small lg:grid-cols-project-card-list grid auto-rows-max items-center gap-x-4 gap-y-0">
					{[
						1,
						2,
						getFeatureFlagValue("PROJECT_DISPLAY_MESSAGE_COUNT") ===
							true && 3,
						getFeatureFlagValue(
							"PROJECT_DISPLAY_ENERGY_SUPPLIER_LINK",
						) === true && 4,
					]
						.filter(Boolean)
						.map((index) => (
							<Fragment key={index as number}>
								<div className="flex h-8 items-center justify-center">
									<div className="dummy-text w-full rounded-md bg-gray-100" />
								</div>
								<div>
									<p className="dummy-text rounded-lg bg-gray-100" />
								</div>
							</Fragment>
						))}
				</div>
			</div>
		</div>
	)
}
