import { Suspense, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { CardBody, CardWrapper } from "@/components/Card"
import { FormGroup } from "@/components/form-controls/FormGroup"
import { TwoFactorAuthSettings } from "./2FA/TwoFactorAuthSettings"
import { Heading } from "@/components/Typography"
import { useTrans } from "@/i18n"
import { UserMFAMethod } from "@/api/rest/generated/@types"
import { useProfileSecurityIntroQuery } from "@/api/graphql"
import { Trans } from "@/i18n"
import { Pages } from "@/misc/pages"
import { ConfigResponse } from "@/api/rest/generated/@types"
import {
	useApiAuthMfaUserActiveMethodsList,
	useApiAuthMfaConfigRetrieve,
} from "@/api/rest/generated/api/api"

export const ProfileSecurityIndex = () => {
	const t = useTrans("profile")
	const [userMfaState, setUserMfaState] = useState<UserMFAMethod[]>([])
	const [mfaState, setMfaState] = useState<ConfigResponse>()

	// this is prefetching user info (phone number) to avoid <Suspense />
	// while user is in progress of enabling 2FA via SMS
	useProfileSecurityIntroQuery()

	const { isSuccess, isLoading, data } = useApiAuthMfaUserActiveMethodsList()

	useEffect(() => {
		if (isSuccess) {
			setUserMfaState(data)
		}
	}, [data, isSuccess])

	const {
		isSuccess: isMfaQuerySuccess,
		isLoading: isMfaQueryLoading,
		data: dataMfa,
	} = useApiAuthMfaConfigRetrieve()

	useEffect(() => {
		if (isMfaQuerySuccess) {
			setMfaState(dataMfa)
		}
	}, [dataMfa, isMfaQuerySuccess])

	return (
		<CardWrapper>
			<CardBody>
				<Heading as="h5" className="mb-3 sm:truncate">
					{t("profile.security.heading")}
				</Heading>
				<Suspense fallback={null}>
					<FormGroup
						heading={
							<>
								{isLoading === false && (
									<p className="whitespace-pre-wrap">
										{userMfaState.length > 0
											? t("profile.security.copy")
											: t(
													"profile.security.two_factor_auth_intro.copy",
											  )}
									</p>
								)}
								{isMfaQueryLoading === false &&
									mfaState?.allow_backup_codes_regeneration && (
										<p className="whitespace-pre-wrap">
											<Trans
												className="mt-2"
												ns="profile"
												i18nKey={
													"profile.security.regenerate_codes"
												}
												components={{
													Link: (
														<Link
															className="font-bold"
															to={
																Pages.SettingsProfileSecurityRegenerateBackupCodes
															}
														></Link>
													),
												}}
											/>
										</p>
									)}
							</>
						}
					>
						<div className="space-y-8">
							<div className="xl:w-3/4">
								<TwoFactorAuthSettings />
							</div>
						</div>
					</FormGroup>
				</Suspense>
			</CardBody>
		</CardWrapper>
	)
}
