export const downloadText = (text: string) => {
	const element = document.createElement("a")
	element.setAttribute(
		"href",
		"data:text/plain;charset=utf-8," + encodeURIComponent(text),
	)
	element.setAttribute("download", "zonnepanelendelen-recovery-codes.txt")
	document.body.appendChild(element)
	element.click()
}
