import { CloudIcon, HomeIcon, ScaleIcon } from "@heroicons/react/outline"
import ms from "ms"
import { ReactNode } from "react"
import { BsFillLightningFill } from "react-icons/bs"
import { useLang } from "@/context/lang"
import { Trans, useTrans } from "@/i18n"
import { Counter } from "./Counter"
import { MiniGraph } from "./MiniGraph"

const formatToDecimalPlaces = (num: number) => Math.round(num * 100) / 100

const DISPLAY_CO2_REDUCTION = true

export const GlobalOverview = () => {
	const { formatCurrency, formatNumber } = useLang()
	const t = { dashboard: useTrans("dashboard"), common: useTrans("common") }
	const interval = ms("1 day")
	const interval1month = ms("90 days")
	const now = Number(new Date())

	return (
		<>
			<MiniGraph
				icon={HomeIcon}
				title={t.dashboard("dashboard.global_overview.stat1.title")}
				heading={(value) => {
					let i18n = ""
					if (value >= 1_000_000 && value < 1_000_000_000) {
						i18n = "common.numbers.million"
						value = value / 1_000_000
					} else if (
						value >= 1_000_000_000 &&
						value < 1_000_000_000_000
					) {
						i18n = "common.numbers.billion"
						value = value / 1_000_000_000
					} else if (value >= 1_000_000_000_000) {
						i18n = "common.numbers.trillion"
						value = value / 1_000_000_000_000
					}

					value = Math.floor(value)

					const amount = i18n
						? t.common(i18n, {
								number: formatNumber(value),
						  })
						: formatNumber(value)

					return <Counter text={amount} height="1.35em" />
				}}
				data={[
					[now - interval1month * 3, 50334 / 2.3],
					[now - interval1month * 2, 50334 / 2.2],
					[now - interval1month, 50334 / 1.3],
					[now, 50334],
				].map(([time, value]) => ({
					date: `${time}`,
					value,
				}))}
			/>
			<MiniGraph
				icon={ScaleIcon}
				title={t.dashboard("dashboard.global_overview.stat3.title")}
				heading={(value) => {
					let i18n = ""
					if (value >= 1_000_000 && value < 1_000_000_000) {
						i18n = "common.numbers.million"
						value = formatToDecimalPlaces(value / 1_000_000)
					} else if (
						value >= 1_000_000_000 &&
						value < 1_000_000_000_000
					) {
						i18n = "common.numbers.billion"
						value = formatToDecimalPlaces(value / 1_000_000_000)
					} else if (value >= 1_000_000_000_000) {
						i18n = "common.numbers.trillion"
						value = formatToDecimalPlaces(value / 1_000_000_000_000)
					}

					const amount = i18n
						? t.common(i18n, {
								number: formatCurrency(value),
						  })
						: formatCurrency(value)

					return <Counter text={amount} height="1.35em" />
				}}
				data={[
					[now + interval * 1, 21454967 / 2.1],
					[now + interval * 2, 21454967 / 2],
					[now + interval * 3, 21454967 / 1.9],
					[now + interval * 4, 21454967 / 1.7],
					[now + interval * 5, 21454967 / 1.6],
					[now + interval * 6, 21454967 / 1.3],
					[now + interval * 7, 21454967 / 1.1],
					[now + interval * 8, 21454967],
				].map(([time, value]) => ({
					date: `${time}`,
					value,
				}))}
			/>
			{DISPLAY_CO2_REDUCTION ? (
				<MiniGraph
					icon={CloudIcon}
					title={t.dashboard("dashboard.global_overview.stat4.title")}
					heading={(value) => {
						let i18n = ""
						if (value >= 1_000 && value < 1_000_000) {
							i18n = "common.numbers.thousand"
							value = Math.round(value / 1_000)
						} else if (
							value >= 1_000_000 &&
							value < 1_000_000_000
						) {
							i18n = "common.numbers.million"
							value = Math.round(value / 1_000_000)
						} else if (
							value >= 1_000_000_000 &&
							value < 1_000_000_000_000
						) {
							i18n = "common.numbers.billion"
							value = Math.round(value / 1_000_000_000)
						} else if (value >= 1_000_000_000_000) {
							i18n = "common.numbers.trillion"
							value = Math.round(value / 1_000_000_000_000)
						} else {
							value = Math.round(value)
						}

						const amount = i18n
							? t.common(i18n, {
									number: formatNumber(value),
							  })
							: formatNumber(value)

						return (
							<>
								<Counter text={amount} height="1.35em" />
							</>
						)
					}}
					data={[
						[now + interval * 1, 383231 / 2.1],
						[now + interval * 2, 383231 / 2],
						[now + interval * 3, 383231 / 1.9],
						[now + interval * 4, 383231 / 1.7],
						[now + interval * 5, 383231 / 1.6],
						[now + interval * 6, 383231 / 1.3],
						[now + interval * 7, 383231 / 1.1],
						[now + interval * 8, 383231],
					].map(([time, value]) => ({
						date: `${time}`,
						value,
					}))}
				/>
			) : (
				<MiniGraph
					icon={({ className }) => (
						<BsFillLightningFill size={24} className={className} />
					)}
					title={t.dashboard("dashboard.global_overview.stat2.title")}
					heading={(value) => {
						return (
							<Trans
								ns="dashboard"
								i18nKey={`dashboard.global_overview.stat2.copy`}
								values={{
									amount: formatNumber(value),
								}}
								components={{
									small: <Small />,
								}}
							/>
						)
					}}
					data={[
						[now + interval * 1, 90],
						[now + interval * 2, 92],
						[now + interval * 3, 140],
						[now + interval * 4, 180],
						[now + interval * 5, 280],
						[now + interval * 6, 310],
						[now + interval * 7, 369],
						[now + interval * 8, 400],
					].map(([time, value]) => ({
						date: `${time}`,
						value,
					}))}
				/>
			)}
		</>
	)
}

const Small = ({ children }: { children?: ReactNode }) => (
	<span className="truncate font-sans text-base font-medium normal-case">
		{children}
	</span>
)
