import { classNames } from "@/lib/classnames"

// Images
import Icon from "@/assets/icons/zpd/logo.svg"

export const LoadingScreen = ({ className }: { className?: string }) => {
	return (
		<div
			className={classNames(
				className,
				"flex h-full flex-grow items-center justify-center",
			)}
			data-testid="loading-screen"
		>
			<p className="text-black">
				<img
					src={Icon}
					alt=""
					className="animate-spin-slow"
					width="100"
					height="100"
				/>
			</p>
		</div>
	)
}

export const LoadingScreenMinimal = ({ className }: { className?: string }) => {
	return (
		<div
			className={classNames(
				className,
				"flex items-center justify-center bg-white",
			)}
			data-testid="loading-screen-minimal"
		>
			<p className="text-black">
				<img
					src={Icon}
					alt=""
					className="animate-spin-slow"
					width="100"
					height="100"
				/>
			</p>
		</div>
	)
}
