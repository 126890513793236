import { Suspense } from "react"

// Router
import { Navigate, Route, Routes } from "@/lib/router"

// Auth
import { AuthenticatedRoute } from "@/components/AuthenticatedRoute"
import { UserRoles } from "@/misc/constants"
import {
	AuthInactivityIndicator,
	useAuth,
	AuthStateStatusEnum,
} from "@/context/auth"
import { useCurrentUserInvestmentsCount } from "@/context/user"

// Pages
import { MainLayout } from "@/layouts/MainLayout"
import { LoadingScreen } from "@/screens/LoadingScreens"
import { Pages, LazyLoadingPages } from "./misc/pages"

// Sentry
import { withSentryReactRouterV6Routing } from "@/lib/sentry"

/**
 * Instrument 'Routes' with Sentry Tracing
 * If you're using the <Routes /> component from react-router-dom to define your routes,
 * wrap Routes using Sentry.withSentryReactRouterV6Routing. This creates a higher order component,
 * which will enable Sentry to reach your router context. You can also use Sentry.withSentryReactRouterV6Routing
 * for Routes inside BrowserRouter. MemoryRouter, and HashRouter components:
 *
 * NOTE: This is only needed at the top level of your app, rather than how v4/v5 required wrapping every <Route/> you wanted parametrized.
 * DOCS: https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/
 */
const SentryRoutes = withSentryReactRouterV6Routing(Routes)

/**
 * App
 * @returns
 */
function App() {
	const auth = useAuth()

	/**
	 * Loggout out pages
	 *
	 * NOTE: These are whitelisted in src/misc/routes.ts PAGES_ALLOW_UNAUTH_ERROR which makes sure that they are not redirect to login if they ever encounter an UNAUTH api error.
	 */
	if (
		[
			AuthStateStatusEnum.LOGGED_OUT,
			AuthStateStatusEnum.AUTHENTICATING,
		].includes(auth.status)
	) {
		return (
			<>
				<SentryRoutes>
					<Route
						path={Pages.Login}
						element={<LazyLoadingPages.SignIn />}
					/>
					<Route
						path={Pages.Register}
						element={<LazyLoadingPages.Register />}
					/>
					<Route
						path={Pages.RegisterConfirm}
						element={<LazyLoadingPages.Confirm />}
					/>

					<Route
						path={Pages.RecoverPassword}
						element={<LazyLoadingPages.Recover />}
					/>
					<Route
						path={Pages.ResetPassword}
						element={<LazyLoadingPages.Reset />}
					/>

					<Route
						path={Pages.StatisticsPlatform}
						element={
							<div className="flex h-full items-center justify-center bg-white px-4">
								<div className="mx-auto">
									<div className="mb-8 text-sm md:mb-16">
										<LazyLoadingPages.Dashboard />
									</div>
								</div>
							</div>
						}
					/>
					<Route
						path={Pages.StatisticsPlatformEndex}
						element={
							<div className="flex h-full items-center justify-center bg-white px-4">
								<div className="mx-auto">
									<div className="mb-8 text-sm md:mb-16">
										<LazyLoadingPages.AllProjectsSolarInterestTariff />
									</div>
								</div>
							</div>
						}
					/>
					<Route
						path={`${Pages.StatisticsPlatformSingleProject}/:id`}
						element={<LazyLoadingPages.SingleProjectStats />}
					/>
					<Route
						path="*"
						element={<LoadingScreen className="h-full" />}
					/>
				</SentryRoutes>
			</>
		)
	}

	// Logged in pages
	return (
		<>
			<AuthInactivityIndicator />
			<Suspense fallback={<LoadingScreen className="h-full" />}>
				<MainLayout>
					<Suspense
						fallback={
							<div className="flex h-full flex-grow items-center justify-center">
								<LoadingScreen />
							</div>
						}
					>
						<SentryRoutes>
							{/** Hack to make the login page show dashboard overview */}
							<Route
								path={Pages.Login}
								element={<RedirectToHomePage />}
							/>
							<Route path="/" element={<RedirectToHomePage />} />

							<Route
								path={Pages.StatisticsPlatform}
								element={<LazyLoadingPages.Dashboard />}
							/>
							<Route
								path={Pages.StatisticsPlatformEndex}
								element={
									<LazyLoadingPages.AllProjectsSolarInterestTariff />
								}
							/>
							<Route
								path={`${Pages.StatisticsPlatformSingleProject}/:id`}
								element={
									<LazyLoadingPages.SingleProjectStats />
								}
							/>
							<Route
								path={Pages.StatisticsMySavings}
								element={
									<LazyLoadingPages.StatisticsMySavings />
								}
							/>
							<Route
								path={`${Pages.SettingsProfilePersonalDetails}/*`}
								element={<LazyLoadingPages.Profile />}
							/>
							<Route
								path={`${Pages.InvestorProfile}/*`}
								element={<LazyLoadingPages.Investor />}
							/>
							<Route
								path={Pages.Info}
								element={
									<AuthenticatedRoute
										roles={[UserRoles.admin]}
									>
										<LazyLoadingPages.Info />
									</AuthenticatedRoute>
								}
							/>
							{/**
							 * If the same component is used as the child of multiple <Route>s
							 * at the same point in the component tree, React will see this
							 * as the same component instance and the component’s state
							 * will be preserved between route changes. So we can use this to our
							 * advantage to ensure that the follow routes are rendered okay.
							 * Since we need a project by :id, and also these static routes
							 * to be on the same path.
							 */}
							<Route
								path={`${Pages.InvestmentsProjectsDashboard}/*`}
								element={<LazyLoadingPages.Investments />}
							/>
							<Route
								path={`${Pages.InvestmentsProjects}/:id/*`}
								element={<LazyLoadingPages.Project />}
							/>
							<Route
								path={Pages.InvestmentsProjects}
								element={<RedirectToHomePage />}
							/>

							{/** Finance projects */}
							<Route
								path={`${Pages.FinanceProjects}/:id`}
								element={
									<AuthenticatedRoute
										roles={[
											UserRoles.admin,
											UserRoles.projectOwner,
											UserRoles.energyProvider,
											UserRoles.staff,
										]}
									>
										<LazyLoadingPages.FinancePerformance />
									</AuthenticatedRoute>
								}
							/>
							<Route
								path={Pages.FinanceProjects}
								element={
									<AuthenticatedRoute
										roles={[
											UserRoles.admin,
											UserRoles.projectOwner,
											UserRoles.energyProvider,
											UserRoles.staff,
										]}
									>
										<LazyLoadingPages.FinanceDashboard />
									</AuthenticatedRoute>
								}
							/>
							<Route
								path={`${Pages.FinanceInterestPayments}/*`}
								element={
									<AuthenticatedRoute
										roles={[
											UserRoles.admin,
											UserRoles.projectOwner,
											UserRoles.energyProvider,
											UserRoles.staff,
										]}
									>
										<LazyLoadingPages.Payments />
									</AuthenticatedRoute>
								}
							/>
							<Route
								path={`${Pages.FinanceSolarInterestTariff}`}
								element={
									<AuthenticatedRoute
										roles={[
											UserRoles.admin,
											UserRoles.projectOwner,
											UserRoles.energyProvider,
											UserRoles.staff,
										]}
									>
										<LazyLoadingPages.FinanceSolarInterestTariff />
									</AuthenticatedRoute>
								}
							/>
							<Route
								path={`${Pages.FinanceSolarInterestTariff}/:id/*`}
								element={
									<AuthenticatedRoute
										roles={[
											UserRoles.admin,
											UserRoles.projectOwner,
											UserRoles.energyProvider,
											UserRoles.staff,
										]}
									>
										<LazyLoadingPages.FinanceSolarInterestTariff />
									</AuthenticatedRoute>
								}
							/>
							<Route
								path={`${Pages.FinanceBondsloan}/:id/*`}
								element={
									<AuthenticatedRoute
										roles={[
											UserRoles.admin,
											UserRoles.projectOwner,
											UserRoles.staff,
										]}
									>
										<LazyLoadingPages.FinanceBondsloan />
									</AuthenticatedRoute>
								}
							/>
							<Route
								path="*"
								element={<LazyLoadingPages.NotFound />}
							/>
						</SentryRoutes>
					</Suspense>
				</MainLayout>
			</Suspense>
		</>
	)
}

/**
 * Logic to redirect to homepage
 * @returns
 */
function RedirectToHomePage() {
	const investmentsCount = useCurrentUserInvestmentsCount()
	// Show 'portfolio' if we have investments, otherwise show Platform stats
	return investmentsCount > 0 ? (
		<Navigate to={Pages.InvestmentsProjectsDashboard} />
	) : (
		<Navigate to={Pages.StatisticsPlatform} />
	)
}

export default App
