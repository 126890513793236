import {
	createContext,
	Suspense,
	useContext,
	useState,
	ReactNode,
	useEffect,
} from "react"
import { getChildByType } from "react-nanny"

// UI
import { CardBody } from "../../../Card"
import { GraphHeaderPreloader } from "../../header/GraphHeader"
import { EmptyGridStateAnimated } from "../../../EmptyGridState"
import { ProductionGraphActions } from "./ProductionGraphActions"

// DateTime
import { DateTime, DurationObjectUnits } from "@/lib/dates"

// Context
import { FinancePerformanceContext } from "@/pages/finance/pages/Performance"

export type GraphIntervalType = "year" | "month" | "all"
export const AvailableDatePeriods = {
	DAY: "day",
	MONTH: "month",
	YEAR: "year",
	ALL: "all",
}

type ProductionGraphContextType = {
	graphInterval: "day" | "month"
	setGraphInterval(
		graphInterval: ProductionGraphContextType["graphInterval"],
	): void
	startTime: any
	setStartTime(startTime: any): void
	endTime: any
	setEndTime(endTime: any): void
	knmiDataStatus: "included" | "excluded" | null
	setKnmiDataStatus(
		knmiDataStatus: ProductionGraphContextType["knmiDataStatus"],
	): void
}

const ProductionGraphContext = createContext<ProductionGraphContextType>(null!)

export function useProductionGraphContext() {
	const context = useContext(ProductionGraphContext)

	if (!context) {
		throw new Error(
			"ProductionGraphContext must be used within a ProductionGraphContextProvider",
		)
	}

	return context
}

/// useFinancePerformanceContextOrState
const useFinancePerformanceContextOrState = () => {
	const context = useContext(FinancePerformanceContext)

	const [localStartTime, setLocalStartTime] = useState(
		DateTime.local().toJSDate(),
	)
	const [localEndTime, setLocalEndTime] = useState(
		DateTime.local().toJSDate(),
	)

	if (context) {
		return {
			startTime: context.selectedDateRangeStart,
			setStartTime: context.setSelectedDateRangeStart,
			endTime: context.selectedDateRangeEnd,
			setEndTime: context.setSelectedDateRangeEnd,
		}
	} else {
		return {
			startTime: localStartTime,
			setStartTime: setLocalStartTime,
			endTime: localEndTime,
			setEndTime: setLocalEndTime,
		}
	}
}

type ProductionGraphProps = {
	children: ReactNode
}

/**
 * ProductionGraph
 * @param param0
 * @returns
 */
export function ProductionGraph({ children }: ProductionGraphProps) {
	// Content template
	const content = getChildByType(children, ProductionGraph.Content)
	const footer = getChildByType(children, ProductionGraph.Footer)
	const header = getChildByType(children, ProductionGraph.Header)
	const actions = getChildByType(children, ProductionGraph.Actions)

	// State
	const [graphInterval, setGraphInterval] =
		useState<ProductionGraphContextType["graphInterval"]>("month")
	const [knmiDataStatus, setKnmiDataStatus] =
		useState<ProductionGraphContextType["knmiDataStatus"]>(null)

	// Hooks
	const { startTime, setStartTime, endTime, setEndTime } =
		useFinancePerformanceContextOrState()

	// Determine start and end dates based on interval
	useEffect(() => {
		// When day, start at the beginning of the month
		if (graphInterval === AvailableDatePeriods.DAY) {
			setStartTime(
				DateTime.local()
					.toUTC()
					.startOf(
						AvailableDatePeriods.MONTH as keyof DurationObjectUnits,
					)
					.toJSDate(),
			)
			setEndTime(
				DateTime.local()
					.toUTC()
					.endOf(
						AvailableDatePeriods.MONTH as keyof DurationObjectUnits,
					)
					.toJSDate(),
			)
		}

		if (
			graphInterval === AvailableDatePeriods.MONTH ||
			graphInterval === AvailableDatePeriods.YEAR ||
			graphInterval === AvailableDatePeriods.ALL
		) {
			setStartTime(
				DateTime.local()
					.toUTC()
					.startOf(
						AvailableDatePeriods.YEAR as keyof DurationObjectUnits,
					)
					.toJSDate(),
			)
			setEndTime(
				DateTime.local()
					.toUTC()
					.endOf(
						AvailableDatePeriods.YEAR as keyof DurationObjectUnits,
					)
					.toJSDate(),
			)
		}
	}, [graphInterval, setStartTime, setEndTime])

	// Return provider
	return (
		<ProductionGraphContext.Provider
			value={{
				graphInterval,
				setGraphInterval,
				knmiDataStatus,
				setKnmiDataStatus,
				startTime,
				setStartTime,
				endTime,
				setEndTime,
			}}
		>
			<CardBody className="lg:flex lg:items-center lg:justify-between">
				<div className="w-full lg:w-72">
					<Suspense fallback={<GraphHeaderPreloader />}>
						{header}
					</Suspense>
				</div>
				<div className="mt-4 lg:mt-0 lg:flex">
					{actions ? actions : <ProductionGraphActions />}
				</div>
			</CardBody>
			<div className="h-96">
				<Suspense
					fallback={
						<EmptyGridStateAnimated className="h-[30%] w-full" />
					}
				>
					{content}
				</Suspense>
			</div>
			{footer}
		</ProductionGraphContext.Provider>
	)
}

ProductionGraph.Footer = ({ children }: { children: ReactNode }) => {
	return <>{children}</>
}

ProductionGraph.Content = ({ children }: { children: ReactNode }) => {
	return <>{children}</>
}

ProductionGraph.Header = ({ children }: { children: ReactNode }) => {
	return <>{children}</>
}

ProductionGraph.Actions = ({ children }: { children: ReactNode }) => {
	return <>{children}</>
}

ProductionGraph.LineGraph = ({ children }: { children: ReactNode }) => {
	return <>{children}</>
}
