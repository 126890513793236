/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * ZonHub
 * Project management for Solar
 * OpenAPI spec version: 1.0.0
 */

/**
 * * `passport` - Paspoort
 * `driving_license` - Rijbewijs
 * `national_id_number` - Identiteitskaart
 * `residence_permit` - Verblijfsvergunning
 */
export type DocumentTypeEnum =
	(typeof DocumentTypeEnum)[keyof typeof DocumentTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentTypeEnum = {
	passport: "passport",
	driving_license: "driving_license",
	national_id_number: "national_id_number",
	residence_permit: "residence_permit",
} as const
