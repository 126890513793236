/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * ZonHub
 * Project management for Solar
 * OpenAPI spec version: 1.0.0
 */
import type {
	MutationFunction,
	UseMutationOptions,
	UseMutationResult,
} from "@tanstack/react-query"
import { useMutation } from "@tanstack/react-query"
import { apiWithTokenAuth } from "../../api"
import type {
	KycVerificationDocument,
	KYCVerificationDocumentResponse,
} from ".././@types"

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

export const usersKycDocumentsUploadCreate = (
	userUuid: string,
	kycVerificationDocument: KycVerificationDocument,
) => {
	const formData = new FormData()
	formData.append("full_first_name", kycVerificationDocument.full_first_name)
	formData.append("document_type", kycVerificationDocument.document_type)
	formData.append("expiry_date", kycVerificationDocument.expiry_date)
	formData.append("document", kycVerificationDocument.document)
	if (kycVerificationDocument.document_back !== undefined) {
		formData.append("document_back", kycVerificationDocument.document_back)
	}

	return apiWithTokenAuth<KYCVerificationDocumentResponse>({
		url: `/users/${userUuid}/kyc_documents_upload/`,
		method: "POST",
		headers: { "Content-Type": "multipart/form-data" },
		data: formData,
	})
}

export const getUsersKycDocumentsUploadCreateMutationOptions = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof usersKycDocumentsUploadCreate>>,
		TError,
		{ userUuid: string; data: KycVerificationDocument },
		TContext
	>
}): UseMutationOptions<
	Awaited<ReturnType<typeof usersKycDocumentsUploadCreate>>,
	TError,
	{ userUuid: string; data: KycVerificationDocument },
	TContext
> => {
	const { mutation: mutationOptions } = options ?? {}

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof usersKycDocumentsUploadCreate>>,
		{ userUuid: string; data: KycVerificationDocument }
	> = (props) => {
		const { userUuid, data } = props ?? {}

		return usersKycDocumentsUploadCreate(userUuid, data)
	}

	return { mutationFn, ...mutationOptions }
}

export type UsersKycDocumentsUploadCreateMutationResult = NonNullable<
	Awaited<ReturnType<typeof usersKycDocumentsUploadCreate>>
>
export type UsersKycDocumentsUploadCreateMutationBody = KycVerificationDocument
export type UsersKycDocumentsUploadCreateMutationError = unknown

export const useUsersKycDocumentsUploadCreate = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof usersKycDocumentsUploadCreate>>,
		TError,
		{ userUuid: string; data: KycVerificationDocument },
		TContext
	>
}): UseMutationResult<
	Awaited<ReturnType<typeof usersKycDocumentsUploadCreate>>,
	TError,
	{ userUuid: string; data: KycVerificationDocument },
	TContext
> => {
	const mutationOptions =
		getUsersKycDocumentsUploadCreateMutationOptions(options)

	return useMutation(mutationOptions)
}
