// SEO meta
import { Helmet } from "@/lib/seo"

// Router
import { Pages } from "@/misc/pages"

// Translations
import { useTrans } from "@/i18n"

// UI
import { GraphLabel, GraphLabels } from "@/components/graphs/GraphLabels"
import { Heading } from "@/components/Typography"
import { Card } from "@/components/Card"
import { SolarInterestTariffGraph } from "@/components/graphs/types/area/SolarInterestTariffGraph"

// Template
import { PageTemplate, PageTemplateLink } from "@/templates/PageTemplate"

// REST queries
import { useApiV1ComparisonProjectEndexPriceRetrieve } from "@/api/rest/generated/api/api"

// Graphs
import { GlobalSolarInterestTariffGraph } from "./_components/GlobalSolarInterestTariffGraph"

/**
 * AllProjectsSolarInterestTariff
 * @returns
 */
export function AllProjectsSolarInterestTariff() {
	const t = useTrans(["finance", "common"])

	// Rest Query
	const { data: dataEndexPrice } =
		useApiV1ComparisonProjectEndexPriceRetrieve(1) // TODO: Now uses hardcoded projectId because this data is the same for all projects. Could work without

	return (
		<>
			<Helmet>
				<title>
					{t("finance:finance.solar_interest_tariff.title")}
				</title>
			</Helmet>
			<PageTemplate
				title={t("finance:finance.solar_interest_tariff.title")}
				tabs={
					<>
						<PageTemplateLink
							to={Pages.StatisticsPlatform}
							name={t(
								"common:common.navigation.statistics.platform.link",
							)}
							analyticsId={t(
								"common:common.navigation.statistics.platform.link",
							)}
						/>
						<PageTemplateLink
							to={Pages.StatisticsPlatformEndex}
							name={t(
								"common:common.navigation.statistics.platform.endex.link",
							)}
							analyticsId={t(
								"common:common.navigation.statistics.platform.endex.link",
							)}
						/>
					</>
				}
			>
				{/** Current Endex */}
				<Card className="mb-8 flex flex-col md:w-1/2">
					<Heading as="h3" styleAs="h6" className="mr-2 lg:mr-0">
						Endex 12-0-12
					</Heading>

					{dataEndexPrice?.price ? (
						<>
							{`${dataEndexPrice?.start} - ${dataEndexPrice?.end}`}
							<div>
								${`${dataEndexPrice?.price / 1000} per kWh`}
							</div>
						</>
					) : null}
				</Card>

				{/** Endex graph */}
				<Card>
					<Heading as="h3" styleAs="h6" className="mr-2 lg:mr-0">
						{t(
							"finance:finance.solar_interest_tariff.block.endex_tariffs_full_year.title",
						)}
					</Heading>

					<SolarInterestTariffGraph>
						<SolarInterestTariffGraph.Content>
							<GlobalSolarInterestTariffGraph />
						</SolarInterestTariffGraph.Content>
						<SolarInterestTariffGraph.Footer>
							<GraphLabels className="p-3 px-4 sm:p-5 sm:px-6">
								<GraphLabel
									type="line"
									variant="primary"
									title={t(
										"finance:finance.solar_interest_tariff.block.endex_tariffs",
									)}
								/>
							</GraphLabels>
						</SolarInterestTariffGraph.Footer>
					</SolarInterestTariffGraph>
				</Card>
				<Card className="mt-8">
					<Heading as="h3" styleAs="h6" className="mr-2 lg:mr-0">
						{t(
							"finance:finance.solar_interest_tariff.block.info.title",
						)}
					</Heading>
					<p className="whitespace-break-spaces">
						{t(
							"finance:finance.solar_interest_tariff.block.info.copy",
						)}
					</p>
				</Card>
			</PageTemplate>
		</>
	)
}
