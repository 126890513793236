import { Fragment } from "react"
import { CardBody, CardWrapper } from "@/components/Card"
import { FiscalOverviewByYear } from "./components/FiscalOverviewByYear"
import { Heading } from "@/components/Typography"
import { useTrans } from "@/i18n"

// Errors
import { ErrorBoundary } from "@/components/errors/ErrorBoundary"
import { ErrorState } from "@/components/errors/ErrorState"

export const Fiscal = () => {
	const t = useTrans("investments")

	return (
		<div className={`space-y-8`} data-testid="fiscaloverview">
			<CardWrapper>
				<CardBody>
					<Heading
						as="h2"
						styleAs="h5"
						className="mb-3 sm:truncate"
						data-testid="investments.fiscal.header"
					>
						{t("investments.fiscal.header")}
					</Heading>
					<div className="space-y-4">
						{t("investments.fiscal.copy")
							.split("\n\n")
							.map((text, index) => (
								<p
									className="text-sm text-gray-500 sm:w-3/4 lg:w-1/2"
									key={`${text}.${index}`}
								>
									{text.split("\n").map((text, index) => (
										<Fragment key={`${text}.${index}`}>
											{text}
											<br />
										</Fragment>
									))}
								</p>
							))}
					</div>
				</CardBody>
			</CardWrapper>
			<ErrorBoundary
				fallback={
					<ErrorState
						title={t("investments.fiscal.error.title")}
						message={t("investments.fiscal.error.message")}
					/>
				}
			>
				<FiscalOverviewByYear />
			</ErrorBoundary>
		</div>
	)
}
