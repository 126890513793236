/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * ZonHub
 * Project management for Solar
 * OpenAPI spec version: 1.0.0
 */

/**
 * * `email` - E-mail
 * `sms_api` - sms
 */
export type MethodEnum = (typeof MethodEnum)[keyof typeof MethodEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MethodEnum = {
	email: "email",
	sms_api: "sms_api",
} as const
