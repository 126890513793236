// React
import { Fragment, Suspense, useMemo } from "react"

// Router
import { useParams } from "react-router-dom"

// SEO
import { Helmet } from "@/lib/seo"

// Icons
import {
	FiDownload,
	FiPaperclip,
	FiCalendar,
	FiHash,
	FiMapPin,
} from "react-icons/fi"

// Forms
import { useFormik, FormikProvider } from "formik"
import { FormGroup } from "@/components/form-controls/FormGroup"
import {
	FormikTextArea,
	FormikSubmitButton,
} from "@/components/form-controls/formik"
import { Label } from "@/components/form-controls/Label"

// Utils
import { transformErrorToText } from "@/api/rest/utils"
import { dateFormat } from "@/misc/constants"

// Auth
import { UserRoles } from "@/misc/constants"

// Context
import { useCurrentUserRoles } from "@/context/user"

// UI
import { classNames } from "@/lib/classnames"
import { LoadingScreen } from "@/screens/LoadingScreens"
import { Card } from "@/components/Card"
import { Button } from "@/components/Button"
import { CardWrapper, CardBody, CardFooter } from "@/components/Card"
import { Heading } from "@/components/Typography"
import { useToasts } from "@/context/toasts"

// GraphQL
import { apiV1PaymentOwnerVerificationCreate } from "@/api/rest/generated/api/api"
import {
	usePaymentDetailProjectOwnerQuery,
	PaymentStateEnum,
} from "@/api/graphql"

// Types
import { ActionEnum } from "@/api/rest/generated/@types"

import { DateTime } from "@/lib/dates"

// Translations
import { useLang } from "@/context/lang"
import { Trans, useTrans } from "@/i18n"

// Tables
import { PaymentOverviewForProjectOwner } from "./components/PaymentOverviewForProjectOwner"

/**
 * useCurrentPaymentDetail with only data that Project owners should be able to see!
 * @returns
 */
export function useCurrentPaymentDetail() {
	const { id } = useParams()

	if (!id) {
		throw new Error("Could not read ID from URL parameters")
	}

	return usePaymentDetailProjectOwnerQuery({ id: id })
}

/**
 * @returns PaymentDetailProjectOwner
 */
export function PaymentDetailProjectOwner() {
	const t = useTrans("payments")
	const { id } = useParams()

	// Get data
	const { data, refetch } = useCurrentPaymentDetail()
	const interestPayment = data?.interest_payments?.results?.[0]

	return (
		<div className="space-y-6 md:space-y-8" data-id={id}>
			<Card>
				<div className="md:flex md:space-x-3">
					<Heading as="h4" className="mb-3 mr-auto sm:truncate">
						{t("payments.copy.title")}
					</Heading>
					<div className="flex space-x-3">
						<Button
							variant="primary"
							onClick={() => {
								window.location.href =
									data?.interest_payments?.results?.[0]
										?.download_pdf_url || ""
							}}
						>
							<FiDownload className="mr-2" />
							{t("payments.pdf.button")}
						</Button>
						<Button
							variant="primary"
							onClick={() => {
								window.location.href =
									data?.interest_payments?.results?.[0]
										?.download_csv_url || ""
							}}
						>
							<FiDownload className="mr-2" />
							{t("payments.csv.button")}
						</Button>
					</div>
				</div>
				{t("payments.copy.owner", {
					name: data?.me?.first_name,
				})
					.split("\n\n")
					.map((text, index) => (
						<p
							key={`${index}.${text}`}
							className="mt-4 text-sm text-gray-700"
						>
							{text.split("\n").map((text, index) => (
								<Fragment key={`${index}.${text}`}>
									{text}
									<br />
								</Fragment>
							))}
						</p>
					))}
				<dt className="mt-8 text-sm font-medium text-gray-500">
					{t("payments.info_list.attachments.heading")}
				</dt>
				<dd className="mt-1 text-sm text-gray-700">
					<ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
						<DownloadPDF />
						<DownloadCSV />
					</ul>
				</dd>
			</Card>

			{/** Payment info list */}
			<PaymentInfoList />
			<Suspense fallback={<LoadingScreen className="h-full" />}>
				<CardWrapper>
					<PaymentOverviewForProjectOwner />
				</CardWrapper>
			</Suspense>

			{/** Show if pending supplier */}
			{interestPayment?.state === PaymentStateEnum.PendingOwner ? (
				<PaymentDetailConfirmation refetch={refetch} />
			) : (
				<CardWrapper>
					<CardBody>
						<Heading as="h3" className="mb-3">
							{t("payments.confirmation.finished.date", {
								date: DateTime.fromISO(
									interestPayment?.project_owner_acceptance_date_time,
								).toFormat(dateFormat),
							})}
						</Heading>
						{t("payments.confirmation.finished.title")}
					</CardBody>
				</CardWrapper>
			)}
		</div>
	)
}

function DownloadPDF() {
	const { data } = useCurrentPaymentDetail()
	const t = useTrans("payments")

	if (!data?.interest_payments?.results?.[0]?.download_pdf_url) return null

	return (
		<a
			href={data.interest_payments.results[0]?.download_pdf_url}
			target="_blank"
			rel="noreferrer"
			className="group flex w-full items-center justify-between py-3 pl-3 pr-4 text-sm hover:bg-gray-100"
			data-testid="downloadpdf"
		>
			<div className="flex items-center">
				<FiPaperclip
					className="h-5 w-5 flex-shrink-0 text-gray-400"
					aria-hidden="true"
				/>
				<span className="ml-2 flex-1 truncate">
					{t("payments.info_list.attachments.pdf.cta", {
						name: data.interest_payments.results[0]?.project?.name,
					})}
				</span>
			</div>
			<div className="text-secondary-300 group-hover:text-secondary-700 ml-4 font-medium">
				{t("payments.info_list.attachments.pdf.download")}
			</div>
		</a>
	)
}

function DownloadCSV() {
	const { data } = useCurrentPaymentDetail()
	const t = useTrans("payments")

	if (!data?.interest_payments?.results?.[0]?.download_csv_url) return null

	return (
		<a
			href={data.interest_payments.results[0]?.download_csv_url}
			target="_blank"
			rel="noreferrer"
			className="group flex w-full items-center justify-between py-3 pl-3 pr-4 text-sm hover:bg-gray-100"
			data-testid="downloadcsv"
		>
			<div className="flex items-center">
				<FiPaperclip
					className="h-5 w-5 flex-shrink-0 text-gray-400"
					aria-hidden="true"
				/>
				<span className="ml-2 flex-1 truncate">
					{t("payments.info_list.attachments.csv.cta", {
						name: data.interest_payments.results[0]?.project?.name,
					})}
				</span>
			</div>
			<div className="text-secondary-300 group-hover:text-secondary-700 ml-4 font-medium">
				{t("payments.info_list.attachments.csv.download")}
			</div>
		</a>
	)
}

/**
 * PaymentInfoList
 * @returns
 */
function PaymentInfoList() {
	const { formatCurrency, formatDate, config, formatNumber } = useLang()
	const t = useTrans("payments")

	const { data } = useCurrentPaymentDetail()

	const interestPayment = data?.interest_payments?.results?.[0]

	const currencyCentsFormatter = useMemo(
		() =>
			new Intl.NumberFormat(config.locale, {
				style: "currency",
				maximumFractionDigits: 8,
				currency: config.currency,
			}),
		[config.currency, config.locale],
	)

	return (
		<>
			<Helmet>
				<title>
					{t("payments.info_list.title", {
						name: interestPayment?.project?.name,
					})}
				</title>
			</Helmet>
			<CardWrapper className="bg-white">
				<CardBody className="border-b border-gray-200">
					<div className="flex justify-between">
						<Heading as="h3" className="mb-1">
							{t("payments.info_list.header", {
								name: interestPayment?.project?.name,
							})}
						</Heading>
					</div>
					<div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-8">
						<div className="mt-2 flex items-center text-sm text-gray-500">
							<FiCalendar
								className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
								aria-hidden="true"
							/>
							<span className="text-gray-400">
								<Trans
									ns="payments"
									i18nKey="payments.info_list.date"
									values={{
										from: DateTime.fromISO(
											interestPayment?.interestperiod
												?.start,
										).toFormat(
											t(
												"payments.info_list.date.date_format",
											),
										),
										to: DateTime.fromISO(
											interestPayment?.interestperiod
												?.end,
										).toFormat(
											t(
												"payments.info_list.date.date_format",
											),
										),
									}}
									components={{
										strong: (
											<span className="font-medium" />
										),
									}}
								/>
							</span>
						</div>
						<div className="mt-2 flex items-center text-sm text-gray-500">
							<FiHash
								className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
								aria-hidden="true"
							/>
							{t("payments.info_list.transaction_id", {
								id: interestPayment?.transaction_id,
							})}
						</div>
						<div className="mt-2 flex items-center text-sm text-gray-500">
							<FiMapPin
								className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
								aria-hidden="true"
							/>
							{t("payments.info_list.location", {
								location: interestPayment?.project?.city,
							})}
						</div>
					</div>
				</CardBody>
				<dl>
					<div
						className={classNames(
							"bg-gray-50 hover:bg-gray-100",
							"px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6",
						)}
					>
						<dt className="text-sm text-gray-500">
							{t("payments.info_list.tariff.title")}
						</dt>
						<dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
							{/* cost_kwh is in cents */}
							{/* TODO: round currency to 4 digits. compare with mijnstroom dashboard */}
							{interestPayment?.interestperiod?.cost_kwh
								? currencyCentsFormatter.format(
										interestPayment.interestperiod
											.cost_kwh / 100,
								  )
								: 0}
						</dd>
					</div>
					<div
						className={classNames(
							"hover:bg-gray-100",
							"px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6",
						)}
					>
						<dt className="text-sm text-gray-500">
							{t(
								"payments.info_list.rental_period_from_until.title",
							)}
						</dt>
						<dd className="text-sm text-gray-700 sm:col-span-2 sm:mt-0">
							{formatDate(
								DateTime.fromISO(
									interestPayment?.interestperiod?.start,
								),
								{ variant: "verbose" },
							)}
							{" / "}
							{formatDate(
								DateTime.fromISO(
									interestPayment?.interestperiod?.end,
								),
								{ variant: "verbose" },
							)}
						</dd>
					</div>

					<div
						className={classNames(
							"bg-gray-50 hover:bg-gray-100",
							"px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6",
						)}
					>
						<dt className="text-sm text-gray-500">
							{t("payments.info_list.number_of_shares.title")}
						</dt>
						<dd className="text-sm text-gray-700 sm:col-span-2 sm:mt-0">
							{interestPayment?.project?.total_shares
								? formatNumber(
										interestPayment.project.total_shares,
								  )
								: 0}
						</dd>
					</div>
					<div
						className={classNames(
							"hover:bg-gray-100",
							"px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6",
						)}
					>
						<dt className="text-sm text-gray-500">
							{t("payments.info_list.total_production.title")}
						</dt>
						<dd className="text-sm text-gray-700 sm:col-span-2 sm:mt-0">
							{t("payments.info_list.total_production.copy", {
								number: interestPayment?.total_production
									? formatNumber(
											interestPayment.total_production,
									  )
									: 0,
							})}
						</dd>
					</div>

					{/** Zonnedelers production */}
					<div
						className={classNames(
							"bg-gray-50 hover:bg-gray-100",
							"px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6",
						)}
					>
						<dt className="text-sm text-gray-500">
							{t(
								"payments.info_list.total_production_investor.title",
							)}
						</dt>
						<dd className="text-sm text-gray-700 sm:col-span-2 sm:mt-0">
							{t(
								"payments.info_list.total_production_investor.copy",
								{
									number: interestPayment?.total_production_investor
										? formatNumber(
												parseFloat(
													interestPayment.total_production_investor,
												),
										  )
										: 0,
								},
							)}
						</dd>
					</div>

					{/** zonnedelers production limited */}
					<div
						className={classNames(
							"hover:bg-gray-100",
							"px-4 py-5  sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6",
						)}
					>
						<dt className="text-sm text-gray-500">
							{t(
								"payments.info_list.total_production_investor_limited.title",
								{
									interest_per_share_limit_applied:
										interestPayment?.interest_per_share_limit_applied,
								},
							)}
						</dt>
						<dd className="text-sm text-gray-700 sm:col-span-2 sm:mt-0">
							{t(
								"payments.info_list.total_production_investor.copy",
								{
									number: interestPayment?.total_production_investor_limited
										? formatNumber(
												parseFloat(
													interestPayment.total_production_investor_limited,
												),
										  )
										: 0,
								},
							)}
						</dd>
					</div>

					{/** Aflossing / amortization */}
					<div
						className={classNames(
							"bg-gray-50 hover:bg-gray-100",
							"px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6",
						)}
					>
						<dt className="text-sm text-gray-500">
							{t("payments.info_list.total_amortization.title")}
						</dt>
						<dd className="text-sm text-gray-700 sm:col-span-2 sm:mt-0">
							{formatCurrency(
								interestPayment?.interestperiod?.amortization
									? interestPayment.interestperiod
											.amortization
									: 0,
							)}
						</dd>
					</div>
					{/** Rente / interest_amount */}
					<div
						className={classNames(
							"hover:bg-gray-100",
							"px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6",
						)}
					>
						<dt className="text-sm text-gray-500">
							{t("payments.info_list.total_interest.title")}
						</dt>
						<dd className="text-sm text-gray-700 sm:col-span-2 sm:mt-0">
							{formatCurrency(
								interestPayment?.interest_amount
									? parseFloat(
											interestPayment?.interest_amount,
									  )
									: 0,
							)}
						</dd>
					</div>
					{/** Afrekening / repayment */}
					<div
						className={classNames(
							"bg-gray-50 hover:bg-gray-100",
							"px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6",
						)}
					>
						<dt className="text-sm text-gray-500">
							{t("payments.info_list.total_repayment.title")}
						</dt>
						<dd className="text-sm text-gray-700 sm:col-span-2 sm:mt-0">
							{formatCurrency(
								interestPayment?.interestperiod?.repayment
									? interestPayment.interestperiod.repayment
									: 0,
							)}
						</dd>
					</div>
					{/** Total */}
					<div
						className={classNames(
							"hover:bg-gray-100",
							"px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6",
						)}
					>
						<dt className="text-sm text-gray-500">
							{t("payments.info_list.total_sum.title")}
						</dt>
						<dd className="text-sm text-gray-700 sm:col-span-2 sm:mt-0">
							{formatCurrency(
								(interestPayment?.interestperiod?.amortization
									? interestPayment.interestperiod
											.amortization
									: 0) +
									(interestPayment?.interest_amount
										? parseFloat(
												interestPayment?.interest_amount,
										  )
										: 0) +
									(interestPayment?.interestperiod?.repayment
										? interestPayment.interestperiod
												.repayment
										: 0),
							)}
						</dd>
					</div>
				</dl>
			</CardWrapper>
		</>
	)
}

function PaymentDetailConfirmation({ refetch }: { refetch: Function }) {
	const { userRoles } = useCurrentUserRoles()
	const t = useTrans("payments")
	const { id } = useParams()
	const toasts = useToasts()

	const form = useFormik({
		initialValues: { notes: "", status: "" },
		onSubmit: async (values, helpers) => {
			helpers.setStatus("")
			try {
				// Project owner
				if (userRoles.has(UserRoles.projectOwner)) {
					const response = await apiV1PaymentOwnerVerificationCreate(
						Number(id),
						{
							action: values.status as ActionEnum,
							message: values.notes,
						},
					)

					// When payment has been confirmed
					if (response.ok === true) {
						toasts.addToast({
							variant: "success",
							id: `handleApiV1PaymentOwnerVerificationCreate-${Date.now()}`,
							text:
								values.status === ActionEnum.accept
									? t("payments.confirmation.success.accept")
									: t("payments.confirmation.success.reject"),
						})
						refetch()
					}
				}
			} catch (e) {
				helpers.setStatus(
					transformErrorToText(e, {
						filteredErrorMessages: new Set("insufficient_rights"),
					}),
				)
			}
		},
	})

	const hasErrorStatus =
		Boolean(form.status) && typeof form.status === "string"

	return (
		<FormikProvider value={form}>
			<form onSubmit={form.handleSubmit}>
				<CardWrapper>
					<CardBody>
						<Heading as="h3" className="mb-3">
							{t("payments.confirmation.title")}
						</Heading>
						<FormGroup heading={t("payments.confirmation.copy")}>
							<div className="space-y-6 sm:space-y-5">
								<div>
									<Label className="truncate" htmlFor="notes">
										{t("payments.confirmation.note")}
									</Label>
									<FormikTextArea
										name="notes"
										rows={5}
										aria-label="notes"
										className="block w-full lg:w-1/2"
									/>
								</div>
								<div>
									<p className="text-sm text-gray-500">
										{t(
											"payments.confirmation.owner.approve_deny.copy",
										)}
									</p>
								</div>
							</div>
						</FormGroup>
					</CardBody>
					<CardFooter className="text-right">
						<div className="flex items-center justify-end space-x-3">
							{hasErrorStatus && (
								<p className="mr-4 font-medium text-red-500">
									{t(
										`payments.confirmation.form.error.${form.status}`,
									)}
								</p>
							)}
							<FormikSubmitButton
								disableWhenPristine={false}
								onClick={() =>
									form.setFieldValue("status", "reject")
								}
								variant={"dark"}
							>
								{t("payments.confirmation.form.reject")}
							</FormikSubmitButton>
							<FormikSubmitButton
								disableWhenPristine={false}
								onClick={() =>
									form.setFieldValue("status", "accept")
								}
								variant={"primary"}
							>
								{t("payments.confirmation.form.send")}
							</FormikSubmitButton>
						</div>
					</CardFooter>
				</CardWrapper>
			</form>
		</FormikProvider>
	)
}
