// Fetch library
import { AxiosRequestConfig, AxiosHeaders } from "axios"

// Constants
import { NetworkError } from "../../api/rest/network-errors"

// Cookie storage
import { get } from "@/lib/cookies"

// State
import { store } from "@/state/store"

// Routes
import { Pages, PAGES_ALLOW_UNAUTH_ERROR } from "@/misc/routes"

// Environment variables
import { isDevelopment, isProduction } from "@/misc/helpers"
import { AUTH_LOGIN_URL, API_URL, STORYBOOK_API_URL } from "@/lib/env"

const normaliseApiUrl = (url: string = "") => {
	if (isProduction() && !url) {
		throw new Error(NetworkError.NO_API_URL)
	}

	// TODO: What is this? Why is legacy-api using NO slash and new-api using WITH slash?
	// make sure no slash is present at the end of the url
	const withSlash = url.replace(/\/$/, "")

	// in production we force https
	// so the url starts with https://
	if (!isDevelopment()) {
		if (!url.includes("127.0.0.1")) {
			return withSlash.replace(/^(https?:\/\/)?/, "https://")
		}
	}

	return withSlash
}

const apiUrlOverride = () => {
	try {
		if (typeof window !== "undefined") {
			return new URL(window.location.href).searchParams.get("mockapi")
		}
	} catch {}
	return ""
}

const apiUrl = normaliseApiUrl(apiUrlOverride() || API_URL || STORYBOOK_API_URL)

// TODO: Add handling exception responses (http response.status codes)
const _api = async <T>({
	url,
	method,
	headers: incomingHeaders, // TODO: Temp hack to make Authorization: Token work
	params,
	data,
}: AxiosRequestConfig): Promise<T> => {
	const options: RequestInit = {
		method,
	}

	if (data) {
		try {
			options.body = JSON.stringify(data)
		} catch (e) {
			console.error(e)
		}
	}

	// Setup new Headers
	const newHeaders = new AxiosHeaders()

	// Process incoming headers and set default values if necessary
	if (incomingHeaders) {
		Object.entries(incomingHeaders).forEach(([header, value]) => {
			newHeaders.set(header, value as string)
		})
	}

	// Set default headers if not already present
	if (!newHeaders.has("accept")) {
		newHeaders.set("Accept", "application/json")
	}
	if (!newHeaders.has("content-type")) {
		newHeaders.set("Content-Type", "application/json")
	}

	// TODO: This hack is here to allow cookies from mijnstroom > zonhub to work
	newHeaders.set("X-CSRFToken", get("csrftoken") || "")

	// Format data body before we send it
	if (data && newHeaders.get("content-type") === "application/json") {
		try {
			options.body = JSON.stringify(data)
		} catch (e) {
			console.error(NetworkError.PARSE_JSON_BODY_ERROR, e)
		}
	}

	// Multipart formdata
	if (data && newHeaders.get("content-type") === "multipart/form-data") {
		/**
		 * Clear Content-type header.
		 * NOTE: This is really important. The browser wants to generate the content-type on its own. And in doing
		 * so will add unique 'boundaries' to the request that allow the backend to parse the formdata.
		 */
		newHeaders.clear("Content-Type")
		try {
			options.body = data
		} catch (e) {
			console.error(NetworkError.PARSE_FORM_BODY_ERROR, e)
		}
	}

	// Filter out params that are undefined
	const filteredParams = Object.entries({ ...params })
		.filter(([_key, value]) => value !== undefined)
		.reduce((obj, [key, value]) => {
			// @ts-ignore
			obj[key] = value
			return obj
		}, {})

	// Format request url
	const requestUrl = `${apiUrl}${url}?${new URLSearchParams(filteredParams)}`

	// Perform request
	const response = await fetch(requestUrl, {
		...options,
		headers: newHeaders,
		credentials: "include", // Send cookies
		mode: "cors", // Allow connecting to API on different domain,
	})

	// When Unauthorized
	if (response.status === 401) {
		// Only redirect to login if we are not already on login!
		if (
			PAGES_ALLOW_UNAUTH_ERROR.includes(location.pathname as Pages) ===
			false
		) {
			window.location.href = AUTH_LOGIN_URL
		}
	}

	// Incorrect permissions
	if (response.status === 403) {
		throw new Error("HTTP_403_FORBIDDEN")
	}
	if (response.status >= 400 && response.status < 500) {
		// When status is in range 4xx -  We do parse the JSON response
		try {
			const json = await response.json()
			return json
		} catch (error) {
			// Return empty object to avoid breaking code
			return {} as T
		}
	}

	/**
	 * Successfull response (http status between 200-226)
	 * TODO: Figure out what this code does when it throws response
	 */
	if (!response.ok) {
		throw response
	}

	// avoid JSON processing for "No Content" responses
	if (response.status === 204) {
		return {} as T
	}

	const json = await response.json()

	return json
}

// With token auth
export const apiWithTokenAuth = async <T>(config: AxiosRequestConfig) => {
	// New headers
	const headers = new AxiosHeaders()
	const incomingHeaders = config.headers

	// Process incoming headers and set them
	if (incomingHeaders) {
		Object.entries(incomingHeaders).forEach(([header, value]) => {
			headers.set(header, value)
		})
	}

	// Add user.authToken to each request
	const authToken = store.getState().auth.token
	if (authToken !== null) {
		headers.set("Authorization", `Token ${authToken}`)
	}

	// Apply everything from regular API
	return _api<T>({ ...config, headers })
}

export const api = async <T>(config: AxiosRequestConfig) => {
	return _api<T>(config)
}

export default api
