/// <reference types="../../../types/dom-to-pdf" />

// React
import { Suspense, useState } from "react"

// SEO
import { Helmet } from "@/lib/seo"

// Dates
import { DateTime } from "@/lib/dates"

// PDF (using this instead of react-to-pdf because it doesnt break content)
import domToPdf from "dom-to-pdf"

// Translations
import { useTrans } from "@/i18n"

// Queries
import { PayOutState, PaymentEntryType } from "@/api/graphql"

// UI
import { CardBody, CardWrapper, Card } from "@/components/Card"
import { Heading } from "@/components/Typography"
import { Button } from "@/components/Button"
import PayoutsTablePrint from "./payments/_components/PayoutsTablePrint"
import PayoutsTableWeb from "./payments/_components/PayoutsTableWeb"
import { ErrorBoundaryWithErrorState } from "@/components/errors/ErrorBoundary"
import { LoadingScreen } from "@/screens/LoadingScreens"

// Icons
import { FiDownload } from "@/lib/icons"
import { TableRowCellVariant } from "@/components/table-controls/TableItems"

/**
 * Extend PaymentEntryType with calculated fields
 * TODO: These can be provided by the backend
 */
export type PaymentEntryTypeExtended = PaymentEntryType & {
	planned_payout_date: DateTime
	state_color: TableRowCellVariant
}

// What color to give the payout state?
export function getPayoutStateColor(
	state: PayOutState | null | undefined,
	paymentPlannedPayoutDate: DateTime,
): TableRowCellVariant {
	// Payouts before the Mangopay migration (2024) have state 'null'. We exclude these from the check
	if (state === null) {
		return "neutral"
	} else if (
		paymentPlannedPayoutDate < DateTime.now() &&
		state !== null &&
		state !== PayOutState.PayoutCompleted &&
		state !== PayOutState.TransferCompleted
	) {
		return "invalid"
	} else {
		return "valid"
	}
}

/**
 * All Payouts
 * @returns
 */
export const AllPayments = () => {
	// State
	const [isPdfLoading, setIsPdfLoading] = useState(false)

	// Translations
	const t = useTrans("project")

	// PDF
	const PDF_FILENAME = "ZonnepanelenDelen - Uitbetalingen.pdf"
	const PDF_VIEW_ID = "payouts-overview-pdf"
	const PDF_VIEW_WIDTH = 1920 // // Width that the table renders optimally at
	async function handlePdfDownload() {
		return await domToPdf(document.getElementById(PDF_VIEW_ID), {
			filename: PDF_FILENAME,
			overrideWidth: PDF_VIEW_WIDTH,
			compression: "FAST",
		})
	}

	// Return
	return (
		<>
			<Helmet>
				<title>{t("project:project.revenue.all.title")}</title>
			</Helmet>

			<Card className="mb-5 xl:w-[60%]">
				<Heading as="h5" className="mb-3 sm:truncate">
					{t("project:project.revenue.all.title")}
				</Heading>
				<p className="mb-2 whitespace-pre-wrap text-sm text-gray-500">
					{t(`project:project.revenue.all.copy`)}
				</p>

				{/** Bullet points */}
				<ul className="ml-0 list-disc whitespace-pre-wrap text-sm text-gray-500">
					<li>{t("project:project.revenue.copy.point.supplier")}</li>
					<li>{t(`project:project.revenue.copy.point.date`)}</li>

					<li>
						{t(
							`project:project.revenue.copy.point.unpaid-payments`,
						)}
					</li>
				</ul>
			</Card>
			<CardWrapper>
				<CardBody>
					{/* filters */}
					<div className="align-end mb-4 flex flex-col justify-between gap-2 md:mb-6 lg:flex-row lg:gap-0">
						<div className="space-between flex w-full flex-wrap gap-x-4 gap-y-2">
							<div className="ml-auto">
								{/** Invisible Print view */}
								<Suspense
									fallback={
										<Button
											disabled
											aria-label="Download"
											size="small"
										>
											<span className="lg:hidden">
												{t(
													"investments:investments.fiscal.download.mobile_text",
												)}
											</span>
											<span className="hidden lg:inline">
												{t(
													"investments:investments.fiscal.download",
												)}
											</span>
											<FiDownload className="ml-2 h-4 w-4" />
										</Button>
									}
								>
									<div
										style={{
											position: "absolute",
											top: 0,
											left: `-${PDF_VIEW_WIDTH}px`,
											width: `${PDF_VIEW_WIDTH}px`,
										}}
									>
										<div id={PDF_VIEW_ID}>
											<PayoutsTablePrint />
										</div>
									</div>
									<Button
										onClick={() => {
											setIsPdfLoading(true)
											handlePdfDownload().then(() => {
												setIsPdfLoading(false)
											})
										}}
										disabled={isPdfLoading}
										aria-label="Download"
										size="small"
									>
										<span className="lg:hidden">
											{t(
												"investments:investments.fiscal.download.mobile_text",
											)}
										</span>
										<span className="hidden lg:inline">
											{t(
												"investments:investments.fiscal.download",
											)}
										</span>
										<FiDownload className="ml-2 h-4 w-4" />
									</Button>
								</Suspense>
							</div>
						</div>
					</div>

					{/** Show table for web */}
					<ErrorBoundaryWithErrorState errorBoundaryClassName="mt-5">
						<Suspense fallback={<LoadingScreen />}>
							<PayoutsTableWeb />
						</Suspense>
					</ErrorBoundaryWithErrorState>
				</CardBody>
			</CardWrapper>
		</>
	)
}
