// React
import {
	createContext,
	Suspense,
	useContext,
	useMemo,
	useState,
	ReactNode,
	ComponentType,
	HTMLProps,
} from "react"

// PDF (using this instead of react-to-pdf because it doesnt break content)
import domToPdf from "dom-to-pdf"

// DateTime
import { DateTime } from "@/lib/dates"
import ms from "ms"
import { apiDateFormat, dateFormat } from "@/misc/constants"
import { useCurrentDate } from "@/hooks/useCurrentDate"

// Translations
import { useTrans } from "@/i18n"
import { useLang } from "@/context/lang"

// Utils
import { groupBy } from "lodash"

// GraphQL
import { useFiscalOverviewByYearQuery } from "@/api/graphql"

// Feature flags
import { useFeatureFlags } from "@/context/user"

// UI
import { Tooltip } from "@/components/Tooltip"
import { Button } from "@/components/Button"
import { CardBody, CardWrapper } from "@/components/Card"
import { DateRangePicker } from "@/components/form-controls/DateRangePicker"
import { Select } from "@/components/Select"
import {
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeading,
	TableRowCell,
} from "@/components/table-controls/TableItems"
import { TableWarning } from "@/components/table-controls/TableWarning"
import { Heading, Subheading, P } from "@/components/Typography"

// Icons
import { FiInfo, FiDownload } from "@/lib/icons"

// Misc
import { useTableMetaData } from "@/hooks/useTableMetaData"
import { sendEvent } from "@/lib/analytics"
import { classNames } from "@/lib/classnames"
import { isWholeNumber } from "@/lib/math"

// Environment variables
import { API_URL } from "@/lib/env"

type FiscalOverviewByYearContextType =
	| { type: "custom"; from: string; to: string }
	| { type: "year"; year: string }

const FiscalOverviewByYearContext =
	createContext<FiscalOverviewByYearContextType>(null!)

// TODO: This is hardcoded and should be changed to start of first investment project?
const startYear = 2016

function useData() {
	return useFiscalOverviewByYearQueryWithYear()
}

function useYearOptions() {
	const now = useCurrentDate(ms("1h"))

	// Note: We want the current year so we do `now.getFullYear() + 1`
	return Array(now.getFullYear() + 1 - startYear)
		.fill(true)
		.map((_, index) => String(startYear + index))
		.reverse()
}

export function getShareNumberRange(data: any) {
	const strList: string[] = []
	const groupedData = groupBy(
		data.map((x: any, i: number) => [i, x]),
		([i, x]) => i - x,
	)

	// Convert object to iterable using Object.entries()
	for (const g of Object.values(groupedData)) {
		const ilist = g.map(([_, item]) => item)
		if (ilist.length > 1) {
			strList.push(`${ilist[0]} to ${ilist[ilist.length - 1]}`)
		} else {
			strList.push(`${ilist[0]}`)
		}
	}
	return strList.join(", ")
}

type Data = {
	projectName?: string
	sharesAtStart?: number
	sharesAtEnd?: number
	shareNumbersAtEnd: string
	valueAtStart?: number
	valueAtEnd?: number
	certificateUrl?: string
}

type Columns<Data> = Array<{
	title: string
	sort?: string
	accessor: (data: Data) => ReactNode
	Footer?: ComponentType<{ rows: Array<Data> }>
	className?: string
	component?: (props: { payment: Data }) => ReactNode
}>

/**
 * FiscalOverviewByYear
 * @returns
 */
export function FiscalOverviewByYear() {
	// State
	const [mode, setMode] = useState<FiscalOverviewByYearContextType>({
		type: "year",
		year: String(DateTime.local().minus({ years: 1 }).year),
	})

	// Translations
	const t = useTrans(["common", "investments"])

	// PDF
	const PDF_FILENAME = "ZonnepanelenDelen - Investeringen.pdf"
	const PDF_VIEW_ID = "fiscal-overview-pdf"
	const PDF_VIEW_WIDTH = 1024 // // Width that the table renders optimally at
	function handlePdfDownload() {
		domToPdf(document.getElementById(PDF_VIEW_ID), {
			filename: PDF_FILENAME,
			overrideWidth: PDF_VIEW_WIDTH,
			compression: "FAST",
		})
	}

	// Dates
	const yearOptions = useYearOptions().map((year) => ({
		key: year,
		value: year,
	}))

	const { getFeatureFlagValue } = useFeatureFlags()

	return (
		<FiscalOverviewByYearContext.Provider value={mode}>
			{/** Invisible Print view */}
			<div
				style={{
					position: "absolute",
					top: 0,
					left: `-${PDF_VIEW_WIDTH}px`,
					width: `${PDF_VIEW_WIDTH}px`,
				}}
			>
				<div id={PDF_VIEW_ID}>
					<FiscalOverviewPdfHeading />
					<FiscalOverviewByYearTable
						isCurrentYearSelected={
							mode.type === "year" &&
							Number(mode.year) === DateTime.local().year
						}
					/>
				</div>
			</div>

			{/** Visible Web view */}
			<CardWrapper>
				<CardBody>
					{/* filters */}
					<div className="align-end mb-4 flex flex-col justify-between gap-2 md:mb-6 lg:flex-row lg:gap-0">
						<div className="space-between flex w-full flex-wrap gap-x-4 gap-y-2">
							<Heading
								as="h3"
								styleAs="h6"
								className="mr-2 lg:mr-0"
							>
								{mode.type === "year"
									? t(
											"investments:investments.fiscal.range.yearly.title",
											{
												year: mode.year,
											},
									  )
									: t(
											"investments:investments.fiscal.range.custom.title",
											{
												from: DateTime.fromISO(
													mode.from,
												).toFormat(dateFormat),
												to: DateTime.fromISO(
													mode.to,
												).toFormat(dateFormat),
											},
									  )}
							</Heading>
							{getFeatureFlagValue(
								"FISCAL_OVERVIEW_BY_YEAR_DISPLAY_BUTTONS",
							) === true && (
								<div className="mb-6 flex items-center">
									<Button
										size="small"
										onClick={() =>
											setMode({
												type: "year",
												year: yearOptions[0]?.key,
											})
										}
										className={classNames(
											"rounded-r-none",
											mode.type === "year" &&
												"relative z-10",
										)}
										variant={
											mode.type === "year"
												? "primary"
												: "transparent"
										}
									>
										{t(
											"investments:investments.fiscal.range.yearly",
										)}
									</Button>
									<Button
										size="small"
										onClick={() => {
											const from = DateTime.local()
												.startOf("year")
												.toISO()
											const to = DateTime.local().toISO()

											setMode({
												type: "custom",
												from,
												to,
											})
										}}
										className={classNames(
											"rounded-l-none border-l-0",
											mode.type === "custom" &&
												"relative z-10",
										)}
										variant={
											mode.type === "custom"
												? "primary"
												: "transparent"
										}
									>
										{t(
											"investments:investments.fiscal.range.custom",
										)}
									</Button>
								</div>
							)}
							{mode.type === "year" && (
								<Select
									value={mode.year}
									onChangeValue={(year) => {
										sendEvent(
											"investments",
											"fiscal_filter",
											{
												label: year,
											},
										)
										year =
											year ||
											String(DateTime.local().year)
										return setMode({
											type: "year",
											year,
										})
									}}
									label={
										<>
											{t(
												"investments:investments.fiscal.filter",
												{
													year: String(mode.year),
												},
											)}
										</>
									}
									options={yearOptions}
									variant="transparent"
								/>
							)}

							{mode.type === "custom" && (
								<DateRangePicker
									from={mode.from}
									to={mode.to}
									onSetFrom={(date: DateTime) =>
										setMode({
											type: "custom",
											from: String(date),
											to: mode.to,
										})
									}
									onSetTo={(date: DateTime) =>
										setMode({
											type: "custom",
											from: mode.from,
											to: String(date),
										})
									}
								/>
							)}

							{getFeatureFlagValue(
								"FEATURE_ENABLE_FISCAL_OVERVIEW_DATE_SELECTOR",
							) === true && (
								<div className="flex items-center">
									<Button
										size="small"
										onClick={() =>
											setMode({
												type: "year",
												year: yearOptions[0]?.key,
											})
										}
										className={classNames(
											"rounded-r-none",
											mode.type === "year" &&
												"relative z-10",
										)}
										variant={
											mode.type === "year"
												? "primary"
												: "transparent"
										}
									>
										{t(
											"investments:investments.fiscal.range.yearly",
										)}
									</Button>
									{getFeatureFlagValue(
										"FEATURE_ENABLE_FISCAL_OVERVIEW_DATE_SELECTOR",
									) === true && (
										<Button
											size="small"
											onClick={() => {
												const from = DateTime.local()
													.startOf("year")
													.toISO()
												const to =
													DateTime.local().toISO()

												setMode({
													type: "custom",
													from,
													to,
												})
											}}
											className={classNames(
												"rounded-l-none border-l-0",
												mode.type === "custom" &&
													"relative z-10",
											)}
											variant={
												mode.type === "custom"
													? "primary"
													: "transparent"
											}
										>
											{t(
												"investments:investments.fiscal.range.custom",
											)}
										</Button>
									)}
								</div>
							)}

							{mode?.type === "year" &&
								Number(mode.year) !== DateTime.local().year && (
									<div className="ml-auto">
										<Button
											onClick={() => {
												sendEvent(
													"investments",
													"fiscal_download",
												)
												handlePdfDownload()
											}}
											aria-label="Download"
											size="small"
										>
											<span className="lg:hidden">
												{t(
													"investments:investments.fiscal.download.mobile_text",
												)}
											</span>
											<span className="hidden lg:inline">
												{t(
													"investments:investments.fiscal.download",
												)}
											</span>
											<FiDownload className="ml-2 h-4 w-4" />
										</Button>
									</div>
								)}
						</div>
					</div>

					{/** Show client reference */}
					<Suspense fallback={null}>
						<FiscalOverviewClientReferenceHeading />
					</Suspense>
					{/* value at end and value at beginning of year overview */}
					<Suspense fallback={<OverviewLoading />}>
						<Overview />
					</Suspense>
				</CardBody>
				<div className="md:hidden">
					<TableWarning
						message={t("common:common.table.mobile_warning.copy")}
					/>
				</div>

				{mode.type === "year" &&
					Number(mode.year) === DateTime.local().year && (
						<TableWarning
							title={t(
								"investments:investments.fiscal.table.disclaimer.title",
							)}
							message={t(
								"investments:investments.fiscal.table.disclaimer.incomplete_data",
							)}
						/>
					)}

				<Suspense fallback={<LoadingStateTable />}>
					<FiscalOverviewByYearTable
						isCurrentYearSelected={
							mode.type === "year" &&
							Number(mode.year) === DateTime.local().year
						}
					/>
				</Suspense>
			</CardWrapper>
		</FiscalOverviewByYearContext.Provider>
	)
}

/**
 * FiscalOverviewClientReferenceHeading
 * @returns
 */
function FiscalOverviewClientReferenceHeading() {
	const t = useTrans("investments")
	const { data } = useData()
	return (
		<SmallCard className="mb-4">
			<P>
				{t("investments.fiscal.overview.client_reference", {
					uuid: data?.me?.uuid,
				})}
			</P>
		</SmallCard>
	)
}

/**
 * FiscalOverviewPdfHeading
 * Show address data etc only on pdf file
 * @returns
 */
function FiscalOverviewPdfHeading() {
	const t = useTrans("investments")
	const { data } = useData()
	const mode = useContext(FiscalOverviewByYearContext)

	return (
		<SmallCard className="mb-4">
			<Heading as="h3">
				{mode.type === "year"
					? t("investments.fiscal.filter", { year: mode.year })
					: t("investments.fiscal.custom_range.filter", {
							from: DateTime.fromISO(mode.from).toFormat(
								dateFormat,
							),
							to: DateTime.fromISO(mode.to).toFormat(dateFormat),
					  })}
			</Heading>
			<Heading as="h4" className="mt-4">
				{data?.me?.full_name}
			</Heading>
			<P>
				{t("investments.fiscal.overview.client_reference", {
					uuid: data?.me?.uuid,
				})}
			</P>
			<P>
				{data?.me?.profile?.address?.address_line_primary}{" "}
				{data?.me?.profile?.address?.address_line_secondary}
			</P>
			{[
				data?.me?.profile?.address?.city,
				data?.me?.profile?.address?.postal_code,
				data?.me?.profile?.address?.region,
				data?.me?.profile?.address?.country,
			]
				.filter(Boolean)
				.map((text, index) => {
					return <P key={`${text}_${index}`}>{text}</P>
				})}
		</SmallCard>
	)
}

function OverviewLoading() {
	return (
		<div className="grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-4">
			<SmallCard>
				<p className="dummy-text mb-1 rounded-md bg-gray-100 text-sm font-medium text-gray-500 md:w-[80%]" />
				<Heading
					as="h2"
					className="dummy-text rounded-md bg-gray-100 text-sm text-gray-900 md:w-[50%]"
				/>
			</SmallCard>
			<SmallCard>
				<p className="dummy-text mb-1 rounded-md bg-gray-100 text-sm font-medium text-gray-500 md:w-[80%]" />
				<Heading
					as="h2"
					className="dummy-text rounded-md bg-gray-100 text-sm text-gray-900 md:w-[50%]"
				/>
			</SmallCard>
		</div>
	)
}

function LoadingStateTable() {
	return (
		<Table className="min-w-[48rem] lg:min-w-0">
			<TableHead>
				<tr role="row">
					<TableHeading
						as="th"
						className="animate-pulse"
						variant="static"
					>
						<p className="dummy-text leading-[2.7]"></p>
					</TableHeading>
				</tr>
			</TableHead>
			<TableBody>
				{Array(10)
					.fill(true)
					.map((_, index) => {
						return (
							<TableRowCell
								isOdd={index % 2 === 0}
								key={index}
								withHover={false}
								className="animate-pulse"
							>
								<TableDataCell>
									<p className="dummy-text"></p>
								</TableDataCell>
							</TableRowCell>
						)
					})}
			</TableBody>
		</Table>
	)
}

/**
 * FiscalOverviewByYearTable
 * @returns
 */
interface FiscalOverviewByYearTableProps {
	isCurrentYearSelected: boolean
}
function FiscalOverviewByYearTable({
	isCurrentYearSelected = false,
}: FiscalOverviewByYearTableProps) {
	const { formatCurrency } = useLang()
	const t = useTrans("investments")
	const { setSort, sort } = useTableMetaData()
	const { data, isPreviousData } = useData()

	const rows = useMemo(() => {
		if (!data?.me?.investment_projects) {
			return []
		}
		return data.me.investment_projects?.results?.map((project) => {
			return {
				projectId: project?.id ?? "",
				projectName: project?.name ?? "",
				sharesAtStart: project?.start?.total_shares ?? undefined,
				valueAtStart: project?.start?.total_investment_value
					? parseFloat(project?.start?.total_investment_value)
					: undefined,
				sharesAtEnd: project?.end?.total_shares ?? undefined,
				shareNumbersAtEnd:
					getShareNumberRange(
						project?.end?.shares?.map((share) =>
							Number(share?.share_number),
						),
					) ?? undefined,
				valueAtEnd: project?.end?.total_investment_value
					? parseFloat(project.end?.total_investment_value)
					: undefined,
				certificateUrl: `${API_URL}api/investor/solar-share-certificate/${project?.id}`,
			}
		})
	}, [data?.me?.investment_projects])

	const columns: Columns<Data> = [
		{
			title: t("investments.fiscal.table_heading.project_name"),
			accessor: (data) => data.projectName,
		},
		{
			title: t("investments.fiscal.table_heading.number_of_shares"),
			accessor: (data) =>
				data.sharesAtStart
					? isWholeNumber(data.sharesAtStart)
						? data.sharesAtStart
						: Number(data.sharesAtStart ?? 0).toFixed(2)
					: "-",
		},
		{
			title: t(
				"investments.fiscal.table_heading.number_of_shares_at_end_of_year",
			),
			accessor: (data) =>
				data.sharesAtEnd
					? isWholeNumber(data.sharesAtEnd)
						? data.sharesAtEnd
						: Number(data.sharesAtEnd ?? 0).toFixed(2)
					: "-",
		},
		{
			title: t("investments.fiscal.table_heading.value_at_start_of_year"),
			accessor: (data) =>
				data.valueAtStart
					? formatCurrency(data.valueAtStart ?? 0)
					: "-",
			Footer: ({ rows }) => {
				const total = useMemo(
					() =>
						rows.reduce((sum, row) => {
							if (row.valueAtStart) return row.valueAtStart + sum
							return sum
						}, 0),
					[rows],
				)

				return (
					<div className="break-word whitespace-pre-wrap">
						<Subheading className="mb-2">
							{t(
								"investments.fiscal.table_footer.value_at_start_of_year",
							)}
						</Subheading>
						<p className="text-sm font-medium text-gray-700">
							{formatCurrency(total)}
						</p>
					</div>
				)
			},
		},
		{
			title: t("investments.fiscal.table_heading.value_at_end_of_year"),
			accessor: (data) =>
				data.valueAtEnd ? formatCurrency(data.valueAtEnd) : "-",
			Footer: ({ rows }) => {
				const total = useMemo(
					() =>
						rows.reduce((sum, row) => {
							if (row.valueAtEnd) return row.valueAtEnd + sum
							return sum
						}, 0),
					[rows],
				)

				return (
					<div className="break-word whitespace-pre-wrap">
						<Subheading className="mb-2">
							{t(
								"investments.fiscal.table_footer.value_at_end_of_year_total",
							)}
						</Subheading>
						<p className="text-sm font-medium text-gray-700">
							{formatCurrency(total)}
						</p>
					</div>
				)
			},
		},
		{
			title: t(
				"investments.fiscal.table_heading.share_numbers_at_end_of_year",
			),
			accessor: (data) => (
				<>
					{/** Download link if current year */}
					{isCurrentYearSelected === true && (
						<a
							href={data.certificateUrl}
							className="flex text-sm underline hover:text-gray-500"
						>
							<FiDownload className="mr-2" />
							<span className="flex-1">
								{data.shareNumbersAtEnd}
							</span>
						</a>
					)}
					{isCurrentYearSelected === false && (
						<>{data.shareNumbersAtEnd}</>
					)}
				</>
			),
		},
	]

	return (
		<>
			<Table className="min-w-[48rem] lg:min-w-0">
				{/* table header */}
				<TableHead>
					<tr role="row">
						{columns.map((header) => {
							const isSorted =
								header.sort && sort?.endsWith(header.sort)
							const isSortedDesc =
								isSorted && sort?.startsWith("-")

							return (
								<TableHeading
									as="th"
									key={header.title}
									colSpan={1}
									variant={
										header.sort ? "sortable" : "static"
									}
									role="columnheader"
									title={header.title}
									isSorted={Boolean(isSorted)}
									isSortedDesc={Boolean(isSortedDesc)}
									onClick={() => {
										if (!header.sort) {
											return
										}

										if (!isSorted && !isSortedDesc) {
											setSort(`-${header.sort}`)
										} else if (isSortedDesc) {
											setSort(header.sort)
										} else {
											setSort("")
										}
									}}
								>
									{header.title}
								</TableHeading>
							)
						})}
					</tr>
				</TableHead>
				{/* table body and table cells */}
				<TableBody
					role="rowgroup"
					className={isPreviousData ? "opacity-25" : ""}
					data-testid="tablebody"
				>
					{rows?.map(({ projectId, ...row }, index) => {
						return (
							<TableRowCell
								key={projectId}
								isOdd={index % 2 === 0}
								role="row"
								data-testid={`tablerow-${projectId}`}
							>
								{columns.map((column) => {
									return (
										<TableDataCell
											key={column.title}
											className="break-word whitespace-pre-wrap"
										>
											{column.accessor(row)}
										</TableDataCell>
									)
								})}
							</TableRowCell>
						)
					})}
				</TableBody>
				{rows && rows.length > 0 ? (
					<tfoot>
						<tr>
							{columns.map((column) => {
								if (column.Footer) {
									return (
										<TableDataCell
											key={column.title}
											data-testid={`footer-${column.title}`}
											className="text-gray-600"
										>
											<column.Footer rows={rows} />
										</TableDataCell>
									)
								}

								return <TableDataCell key={column.title} />
							})}
						</tr>
					</tfoot>
				) : null}
			</Table>
			{rows?.length === 0 && (
				<CardBody>
					<TableEmptyState />
				</CardBody>
			)}
		</>
	)
}

const TableEmptyState = () => {
	const t = useTrans("investments")

	return (
		<div className="space-y-4 p-10 text-center">
			<Heading as="h2" styleAs="h5">
				{t("investments.fiscal.no_results.title")}
			</Heading>
			<p className="text-gray-500">
				{t("investments.fiscal.no_results.copy")}
			</p>
		</div>
	)
}

function useFiscalOverviewByYearQueryWithYear(
	options = { keepPreviousData: false },
) {
	const mode = useContext(FiscalOverviewByYearContext)

	const start: string = useMemo(() => {
		if (mode.type === "custom") {
			return DateTime.fromISO(mode.from).toFormat(apiDateFormat)
		}

		// we need to get the date in NL, so we can set the timezone to Amsterdam
		// then we get the start of the year, and then the backend expects the date in UTC
		// note: i had to set millisecond: 0 otherwise it was coming out in the iso timestamp
		return DateTime.fromObject({ zone: "Europe/Amsterdam" })
			.set({
				year: parseInt(mode.year),
			})
			.startOf("year")
			.toFormat(apiDateFormat)
	}, [mode])

	const end: string = useMemo(() => {
		if (mode.type === "custom") {
			return DateTime.fromISO(mode.to).toFormat(apiDateFormat)
		}

		// we can safely use the beginning of next year since the end date is exlusive
		return DateTime.fromObject({ zone: "Europe/Amsterdam" })
			.set({
				year: parseInt(mode.year) + 1,
			})
			.startOf("year")
			.toFormat(apiDateFormat)
	}, [mode])

	return useFiscalOverviewByYearQuery(
		{
			start,
			end,
		},
		options,
	)
}

function Overview() {
	const mode = useContext(FiscalOverviewByYearContext)
	const t = useTrans("investments")
	const { formatCurrency } = useLang()

	const { data } = useFiscalOverviewByYearQueryWithYear()

	const startValue = data?.me?.start?.total_investment_value
		? parseFloat(data.me.start.total_investment_value)
		: 0

	const endValue = data?.me?.end?.total_investment_value
		? parseFloat(data.me.end.total_investment_value)
		: 0

	if (mode.type === "custom") {
		const start = DateTime.fromISO(mode.from).toFormat(dateFormat)
		const end = DateTime.fromISO(mode.to).toFormat(dateFormat)

		return (
			<div className="grid-cols-2 gap-4 sm:grid-cols-2 xl:grid-cols-4">
				<Tooltip
					content={t(
						"investments.fiscal.hero.returns_custom_range_start.explanation",
						{
							date: start,
						},
					)}
				>
					<div>
						<SmallCard>
							<dt className="relative mb-1 text-sm font-medium text-gray-500">
								{t(
									"investments.fiscal.hero.returns_custom_range_start.title",
									{
										date: start,
									},
								)}
								<FiInfo
									className="absolute right-0 top-0"
									size={18}
								/>
							</dt>
							<dd className="text-sm text-gray-900">
								<Heading as="h2">
									{formatCurrency(startValue)}
								</Heading>
							</dd>
						</SmallCard>
					</div>
				</Tooltip>
				<Tooltip
					content={t(
						"investments.fiscal.hero.returns_end_of_year.explanation",
						{
							date: end,
						},
					)}
				>
					<div>
						<SmallCard>
							<dt className="relative mb-1 text-sm font-medium text-gray-500">
								{t(
									"investments.fiscal.hero.returns_end_of_year.title",
									{
										date: end,
									},
								)}

								<FiInfo
									className="absolute right-0 top-0"
									size={18}
								/>
							</dt>
							<dd className="text-sm text-gray-900">
								<Heading as="h2">
									{formatCurrency(endValue)}
								</Heading>
							</dd>
						</SmallCard>
					</div>
				</Tooltip>
			</div>
		)
	}

	if (mode.type === "year") {
		const { year } = mode

		return (
			<div className="flex gap-4">
				{/** Total Value start of year */}
				<Tooltip
					content={t(
						"investments.fiscal.hero.returns_start_of_year.explanation",
						{
							year,
						},
					)}
				>
					<div className="w-full gap-4 sm:w-1/2 xl:w-1/4">
						<SmallCard>
							<dt className="relative mb-1 text-sm font-medium text-gray-500">
								{t(
									"investments.fiscal.hero.returns_start_of_year.title",
									{
										year,
									},
								)}
								<FiInfo
									className="absolute right-0 top-0"
									size={18}
								/>
							</dt>
							<dd className="text-sm text-gray-900">
								<Heading as="h2">
									{formatCurrency(startValue)}
								</Heading>
							</dd>
						</SmallCard>
					</div>
				</Tooltip>

				{/** Total Value end of year if NOT current year */}
				{year !== String(new Date().getFullYear()) && (
					<Tooltip
						content={t(
							"investments.fiscal.hero.returns_end_of_year.explanation",
							{
								year,
							},
						)}
					>
						<div className="w-full gap-4 sm:w-1/2 xl:w-1/4">
							<SmallCard>
								<dt className="relative mb-1 text-sm font-medium text-gray-500">
									{t(
										"investments.fiscal.hero.returns_end_of_year.title",
										{
											year,
										},
									)}

									<FiInfo
										className="absolute right-0 top-0"
										size={18}
									/>
								</dt>
								<dd className="text-sm text-gray-900">
									<Heading as="h2">
										{formatCurrency(endValue)}
									</Heading>
								</dd>
							</SmallCard>
						</div>
					</Tooltip>
				)}

				{/** Total Value TODAY */}
				{year === String(new Date().getFullYear()) && (
					<Tooltip
						content={t(
							"investments.fiscal.hero.returns_today.explanation",
						)}
					>
						<div className="ml-auto w-full gap-4 sm:w-1/2 xl:w-1/4">
							<SmallCard>
								<dt className="relative mb-1 text-sm font-medium text-gray-500">
									{t(
										"investments.fiscal.hero.returns_today.title",
									)}

									<FiInfo
										className="absolute right-0 top-0"
										size={18}
									/>
								</dt>
								<dd className="text-sm text-gray-900">
									<Heading as="h2">
										{formatCurrency(endValue)}
									</Heading>
								</dd>
							</SmallCard>
						</div>
					</Tooltip>
				)}
			</div>
		)
	}

	return null
}

const SmallCard = ({ className, ...props }: HTMLProps<HTMLDivElement>) => (
	<div
		{...props}
		className={classNames(
			className,
			"grid-rows-[minmax(20px,1fr)_1fr] rounded-md bg-gray-50 px-4 py-3",
		)}
	/>
)
