import { useLang } from "@/context/lang"
import { Languages, configs } from "@/i18n/config"
import { classNames } from "@/lib/classnames"
import { Select } from "./form-controls"

export const LanguageSelector = ({ className }: { className?: string }) => {
	const { config, lang, setLang } = useLang()

	return (
		<label
			className={classNames(
				className,
				"relative flex items-center text-xs",
			)}
		>
			{config.display}
			<Select
				className="absolute left-0 top-0 h-full w-full cursor-pointer p-0 opacity-0"
				onChange={(evt) =>
					setLang(evt.currentTarget.value as Languages)
				}
				value={lang}
				data-testid="language-selector"
			>
				{Object.values(configs).map((config) => (
					<option value={config.path} key={config.path}>
						{config.display}
					</option>
				))}
			</Select>
		</label>
	)
}
