// React rendering
import { memo, StrictMode, Suspense } from "react"
import { createPortal } from "react-dom"
import { createRoot } from "react-dom/client"

// Misc
import fromEntriesShim from "object.fromentries/shim"
import { Helmet, HelmetProvider } from "@/lib/seo"
import { BrowserRouter } from "react-router-dom"
import { apiUrlOverride } from "@/api/rest"

// Tracing & Logging
import { initSentry } from "@/lib/sentry"

// Context Providers
import { StateProvider } from "./state/StateProvider"
import { GlobalErrorBoundary } from "@/components/errors/GlobalErrorBoundary"
import { HiddenGetParamsProvider } from "@/context/hiddenGetParams"
import { AuthProvider } from "@/context/auth"

// Translations
import "@/i18n/browser"
import { LanguageProvider } from "@/context/lang"

// Styles
import "@/styles/index.css"
import { queryClient, QueryClientProvider } from "@/lib/reactQuery"

// UI
import App from "./App"
import { LoadingScreen } from "@/screens/LoadingScreens"
import { ToastProvider, Toasts } from "@/context/toasts"

// Devtools
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"

// Service worker (to support Progressive Web App installation)
// @ts-ignore
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"

// env variables
import { MOCK_BACKEND } from "@/lib/env"

if (!Object.fromEntries) {
	fromEntriesShim()
}

async function registerMockApi() {
	// do not mock api backend when url from flag from url has been set
	if (Boolean(apiUrlOverride)) {
		return
	}

	const enableMocks =
		new URL(window.location.href).searchParams.get("enableMocks") === "true"

	if (MOCK_BACKEND === "true" && enableMocks) {
		const { worker } = await import("./mocks/browser")

		// `onUnhandledRequest` will remove the warning on unhandled requests
		// (such as static assets, manifest, favicon)
		await worker.start({ onUnhandledRequest: "bypass" })
		console.info("msw enabled")
	}
}

function ModalContainer() {
	return createPortal(
		<div />,
		document.getElementById("modals") ?? document.body,
	)
}

function ToastContainer() {
	return createPortal(
		<div className="fixed bottom-0 right-0 z-[160] flex flex-col items-end gap-4 pb-4 pr-4 sm:pb-6 sm:pr-6">
			<Toasts />
		</div>,
		document.getElementById("toasts") ?? document.body,
	)
}

// we do this so that if global providers render, which are placed above `App` in the
// jsx tree, then they do not trigger a render of the whole `App`
const AppMemoized = true ? memo(App) : App

/**
 * main
 */
async function main() {
	try {
		await registerMockApi()
	} catch (error) {
		console.error("Error registering mock API:", error)
	}

	const root = createRoot(document.getElementById("root") ?? document.body)

	initSentry()

	root.render(
		<StrictMode>
			<Suspense fallback={<LoadingScreen className="min-h-screen" />}>
				<StateProvider>
					<LanguageProvider>
						<ToastProvider>
							<QueryClientProvider client={queryClient}>
								<HelmetProvider>
									<GlobalErrorBoundary>
										<Helmet titleTemplate="ZonnepanelenDelen - %s" />
										<BrowserRouter>
											<AuthProvider
												queryClient={queryClient}
											>
												<HiddenGetParamsProvider>
													<AppMemoized />
												</HiddenGetParamsProvider>
											</AuthProvider>
										</BrowserRouter>
										{new URL(
											window.location.href,
										).searchParams.get("debug") !== null ? (
											<ReactQueryDevtools
												initialIsOpen={false}
											/>
										) : null}
										<ModalContainer />
										<ToastContainer />
									</GlobalErrorBoundary>
								</HelmetProvider>
							</QueryClientProvider>
						</ToastProvider>
					</LanguageProvider>
				</StateProvider>
			</Suspense>
		</StrictMode>,
	)
}

// Run main function
main()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()
