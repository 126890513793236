import { ReactNode } from "react"
import { defaultStyles, TooltipWithBounds } from "@visx/tooltip"
import { TooltipWithBoundsProps } from "@visx/tooltip/lib/tooltips/TooltipWithBounds"

export const tooltipStyles = {
	...defaultStyles,
	// fixes text wrapping onto two lines
	whiteSpace: "nowrap" as const,
	background: "#fff",
	border: "0",
	padding: 0,
	maxWidth: "100vw",
}

type TooltipProps = Omit<TooltipWithBoundsProps, "ref" | "style">

export const Tooltip = ({ children, ...props }: TooltipProps) => {
	return (
		<TooltipWithBounds style={tooltipStyles} {...props}>
			{children}
		</TooltipWithBounds>
	)
}

interface TooltipLineProps {
	x: number
	y: number
	y2?: number
	width: number
	height: number
	children?: ReactNode
	fill?: string
}

export const TooltipLine = ({
	width,
	height,
	x,
	y,
	children,
	fill,
}: TooltipLineProps) => {
	return (
		<g pointerEvents="none">
			<rect
				x={x - 2}
				y={y}
				width={width}
				height={height}
				fill={fill}
				pointerEvents="none"
			/>
			{children}
		</g>
	)
}

export function TooltipPoint({
	x,
	y,
	radius = 5,
	fill = "#000",
}: {
	x: number
	y: number
	radius?: number
	fill?: string
}) {
	return (
		<circle
			cx={x}
			cy={y}
			r={radius}
			fill={fill}
			strokeWidth={0}
			pointerEvents="none"
		/>
	)
}
