// React
import { useMemo, ReactNode, ComponentType } from "react"

// Translations
import { useLang } from "@/context/lang"

// UI
import {
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeading,
	TableRowCell,
} from "@/components/table-controls/TableItems"

// Misc
import { useTableMetaData } from "@/hooks/useTableMetaData"

// Types
type TotalRepaidPerYear = {
	year: string
	start: number
	end: number
}
type Columns<TotalRepaidPerYear> = Array<{
	title: string
	sort?: string
	accessor: (data: TotalRepaidPerYear) => ReactNode
	Footer?: ComponentType<{ rows: Array<TotalRepaidPerYear> }>
	className?: string
	component?: (props: { payment: TotalRepaidPerYear }) => ReactNode
}>

/**
 * FinanceBondsLoanByYearTable
 * @returns
 */
export function FinanceBondsLoanByYearTable() {
	const { formatCurrency } = useLang()
	const { setSort, sort } = useTableMetaData()

	// TODO: Remove Dummy data
	const data = {
		me: {
			investment_projects: {
				results: [
					{
						project_shares_value_stats: {
							total_repaid_per_year: [
								{
									year: "2013",
									start: 20000,
									end: 10000,
								},
								{
									year: "2014",
									start: 30000,
									end: 20000,
								},
								{
									year: "2015",
									start: 40000,
									end: 30000,
								},
							],
						},
					},
				],
			},
		},
	}

	const currentProject = data?.me?.investment_projects.results.at(0)

	const rows = useMemo(() => {
		if (!data?.me?.investment_projects) {
			return []
		}
		return currentProject?.project_shares_value_stats.total_repaid_per_year
	}, [data?.me?.investment_projects])

	const columns: Columns<TotalRepaidPerYear> = [
		{
			title: "Fiscaal jaar",
			accessor: (data) => data.year,
		},
		{
			title: "Lening begin van jaar",
			accessor: (data) => formatCurrency(data.start),
		},
		{
			title: "Lening eind van jaar",
			accessor: (data) => formatCurrency(data.end),
		},
	]

	// Template
	return (
		<>
			<Table className="min-w-[48rem] lg:min-w-0">
				{/* table header */}
				<TableHead>
					<tr role="row">
						{columns.map((header) => {
							const isSorted =
								header.sort && sort?.endsWith(header.sort)
							const isSortedDesc =
								isSorted && sort?.startsWith("-")

							return (
								<TableHeading
									as="th"
									key={header.title}
									colSpan={1}
									variant={
										header.sort ? "sortable" : "static"
									}
									role="columnheader"
									title={header.title}
									isSorted={Boolean(isSorted)}
									isSortedDesc={Boolean(isSortedDesc)}
									onClick={() => {
										if (!header.sort) {
											return
										}

										if (!isSorted && !isSortedDesc) {
											setSort(`-${header.sort}`)
										} else if (isSortedDesc) {
											setSort(header.sort)
										} else {
											setSort("")
										}
									}}
								>
									{header.title}
								</TableHeading>
							)
						})}
					</tr>
				</TableHead>
				{/* table body and table cells */}
				<TableBody role="rowgroup" data-testid="tablebody">
					{rows?.map(({ ...row }, index) => {
						return (
							<TableRowCell
								key={row.year}
								isOdd={index % 2 === 0}
								role="row"
							>
								{columns.map((column) => {
									return (
										<TableDataCell
											key={column.title}
											className="break-word whitespace-pre-wrap"
										>
											{column.accessor(row)}
										</TableDataCell>
									)
								})}
							</TableRowCell>
						)
					})}
				</TableBody>
				{rows && rows.length > 0 ? (
					<tfoot>
						<tr>
							{columns.map((column) => {
								if (column.Footer) {
									return (
										<TableDataCell
											key={column.title}
											data-testid={`footer-${column.title}`}
											className="text-gray-600"
										>
											<column.Footer rows={rows} />
										</TableDataCell>
									)
								}

								return <TableDataCell key={column.title} />
							})}
						</tr>
					</tfoot>
				) : null}
			</Table>
		</>
	)
}
