// React
import { useMemo } from "react"

// SEO
import { Helmet } from "@/lib/seo"

// Queries
import { useFinanceBondsLoanQuery } from "@/api/graphql"

// Translations
import { useTrans } from "@/i18n"
import { useLang } from "@/context/lang"

// Router
import { Pages } from "@/misc/pages"

// UI
import { Anchor } from "@/components/Anchor"
import { PageTemplate } from "@/templates/PageTemplate"
import { FinanceProjectSubMenuTabs } from "../components/FinanceProjectSubMenuTabs"
import { Card } from "@/components/Card"
import { Heading } from "@/components/Typography"
import { Tooltip } from "@/components/tooltips/Tooltip"
import { FinanceBondsLoanByYearTable } from "../components/FinanceBondsLoanByYearTable"

// Icons
import { FiFile, FiInfo } from "@/lib/icons"

// Dates
import { DateTime } from "@/lib/dates"
import { dateFormat } from "@/misc/constants"

// Hooks
import { useCurrentFinanceProjectId } from "../hooks/useCurrentFinanceProjectId"

// Utils
import { calculatePlannedPayoutDate } from "@/pages/project/pages/Revenue"

/**
 * FinanceBondsloan
 * @returns
 */
export function FinanceBondsloan() {
	// Translation
	const { formatCurrency } = useLang()

	// Query
	const projectId = useCurrentFinanceProjectId()
	const { data } = useFinanceBondsLoanQuery({
		id: String(projectId),
	})

	const currentProject = useMemo(() => {
		return data?.me?.finance_projects?.results?.at(0)
	}, [data?.me?.finance_projects])
	const currentInterestPeriod = currentProject?.interest_periods?.find(
		(period) => period?.is_current_period,
	)
	const interestPeriodEndDate = DateTime.fromISO(currentInterestPeriod?.end)
	const paymentPlannedPayoutDate = calculatePlannedPayoutDate({
		isFirstPeriod: Boolean(currentInterestPeriod?.is_first_period),
		interestPeriodEndDate,
		paymentPeriodDeadlineDaysFirstPeriodDelay:
			currentProject?.payment_period_deadline_days_first_period_delay,
		paymentPeriodDeadlineDaysDefault:
			currentProject?.payment_period_deadline_days_default,
	})

	// Translation
	const t = useTrans(["finance", "common"])

	return (
		<>
			<Helmet>
				<title>
					{t("finance:finance.bonds-loan.title", {
						projectName: currentProject?.name,
					})}
				</title>
			</Helmet>
			<PageTemplate
				title={t("finance:finance.bonds-loan.title", {
					projectName: currentProject?.name,
				})}
				backHref={Pages.FinanceProjects}
				tabs={<FinanceProjectSubMenuTabs />}
			>
				<div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
					{/** Documents */}
					<Card>
						<Heading
							as="h5"
							className="mb-3 text-gray-600 sm:truncate"
						>
							{t("finance.bonds-loan.block.documents.title")}
						</Heading>
						<div className="grid gap-2">
							{currentProject?.documents?.map((document) => {
								return (
									<div
										key={String(document!.url)}
										className="flex"
									>
										<Anchor
											href={String(document!.url)}
											target="_blank"
											download
											className="text-secondary-300 hover:text-secondary-900 flex items-center text-sm font-medium"
										>
											<FiFile className="mr-2" />
											{document!.title}
										</Anchor>

										{/** Description */}
										{!!document?.description?.length && (
											<Tooltip
												text={document.description}
												targetClassNamesProp={
													"text-gray-500"
												}
											/>
										)}
									</div>
								)
							})}
						</div>
					</Card>

					{/** Interest period */}
					<Card>
						<div className="space-y-3">
							<Heading as="h5" className="text-gray-600">
								{t(
									"finance:finance.bonds-loan.block.interest_periods.title",
								)}
							</Heading>
							<div className="grid grid-cols-2 gap-2">
								<dt className="text-sm font-medium text-gray-500">
									{t(
										"finance:finance.bonds-loan.block.interest_periods.date_end",
									)}
								</dt>
								<dd className="text-right text-sm text-gray-900">
									{interestPeriodEndDate.toFormat(dateFormat)}
								</dd>

								<dt className="text-sm font-medium text-gray-500">
									{t(
										"finance:finance.bonds-loan.block.interest_periods.date_payment",
									)}
								</dt>
								<dd className="text-right text-sm text-gray-900">
									{paymentPlannedPayoutDate.toFormat(
										dateFormat,
									)}
								</dd>
							</div>
						</div>
					</Card>

					{/** Funds */}
					<Card>
						<Heading
							as="h5"
							className="mb-3 text-gray-600 sm:truncate"
						>
							{t(
								"finance:finance.bonds-loan.block.funding.title",
							)}
						</Heading>
						<div className="grid grid-cols-2 gap-2">
							<dt className="text-sm font-medium text-gray-500">
								{t(
									"finance:finance.bonds-loan.block.funding.original_amount",
								)}
							</dt>
							<dd className="text-right text-sm text-gray-900">
								{formatCurrency(0)}
							</dd>

							<dt className="text-sm font-medium text-gray-500">
								{t(
									"finance:finance.bonds-loan.block.funding.loan_outstanding",
								)}
								<Tooltip
									text={t(
										"finance.bonds-loan.block.funding.loan_outstanding.tooltip",
										{
											dateEndOfLastYear: DateTime.now()
												.minus({
													years: 1,
												})
												.toFormat("dd-MM-yyyy")
												.toString(),
										},
									)}
								>
									<FiInfo className="ml-1" />
								</Tooltip>
							</dt>

							<dd className="text-right text-sm text-gray-900">
								{formatCurrency(0)}
							</dd>
						</div>
					</Card>

					{/** Bonds */}
					<Card>
						<Heading
							as="h5"
							className="mb-3 text-gray-600 sm:truncate"
						>
							{t(
								"finance:finance.bonds-loan.block.bonds.current_total_value.title",
							)}
						</Heading>
						<div className="grid grid-cols-2 gap-2">
							<dt className="text-sm font-medium text-gray-500">
								{t(
									"finance:finance.bonds-loan.block.bonds.sum",
								)}
							</dt>

							<dd className="text-right text-sm text-gray-900">
								-
							</dd>
						</div>
					</Card>
				</div>

				{/** Fiscal overview */}
				<Card className="mt-8">
					<Heading as="h5" className="mb-3 text-gray-600 sm:truncate">
						{t(
							"finance:finance.bonds-loan.block.fiscal-overview.title",
						)}
					</Heading>
					<FinanceBondsLoanByYearTable />
				</Card>
			</PageTemplate>
		</>
	)
}
