import { useMemo, useEffect } from "react"
import { DateTime } from "@/lib/dates"
import { CumulativeProductionGraph } from "@/components/visx/CumulativeProductionGraph"

// Graphql
import { useInvestmentsGraphCumulativeProductionQuery } from "@/api/graphql"

// Graphs
import { useProductionCumulativeGraphContext } from "@/components/graphs/types/stacked-area/ProductionCumulative"
import { ProductionCumulativeNoData } from "@/components/graphs/types/stacked-area/ProductionCumulativeNoData"
import { useInvestmentsProjectsFirstDate } from "../hooks/useInvestmentsProjectsFirstDate"

function useProductionCumulativeGraphData() {
	const { graphInterval, startTime, endTime, setMinimumTime } =
		useProductionCumulativeGraphContext()

	// Get start of project
	const { data: projectData } = useInvestmentsProjectsFirstDate()

	// Set the start date of the project as minimum time
	useEffect(() => {
		if (projectData?.project?.start) {
			setMinimumTime(
				DateTime.fromISO(projectData?.project?.start).toJSDate(),
			)
		}
	}, [projectData?.project?.start, setMinimumTime])

	const { data } = useInvestmentsGraphCumulativeProductionQuery({
		startTime,
		endTime,
		// monthly timerange in graph = daily intervals
		// yearly timerange in graph = monthly interals
		// all = monthly interals
		interval: graphInterval === "month" ? "day" : "month",
	})

	return data?.me?.investor_production_stats
}

function useHasNoData() {
	const data = useProductionCumulativeGraphData()

	return (
		data?.cumulative_production_data?.length === 0 ||
		// does the project have no data? we can check this by looking through
		// the array and seeing if all of the values have null or "0".
		// since the api can return a full array, but full of null values or "0"
		data?.cumulative_production_data?.every(
			(value) => value?.production === null || value?.production === "0",
		)
	)
}

export function InvestmentsGraphCumulativeProduction() {
	const { graphInterval } = useProductionCumulativeGraphContext()
	const data = useProductionCumulativeGraphData()
	const hasNoData = useHasNoData()

	const cumulativeProductionData = useMemo(() => {
		return (
			data?.cumulative_production_data?.map((data) => {
				return {
					x: data?.date
						? DateTime.fromISO(data.date).toUTC().toMillis()
						: 0,
					y: data?.production ? parseFloat(data.production) : 0,
				}
			}) ?? []
		)
	}, [data])

	const expectedCumulativeProductionData = useMemo(() => {
		return (
			data?.expected_cumulative_production_data?.map((data) => {
				return {
					x: data?.date
						? DateTime.fromISO(data.date).toUTC().toMillis()
						: 0,
					y: data?.production ? parseFloat(data.production) : 0,
				}
			}) ?? []
		)
	}, [data])

	if (hasNoData) {
		return <ProductionCumulativeNoData />
	}

	return (
		<CumulativeProductionGraph
			data={cumulativeProductionData}
			estimation={expectedCumulativeProductionData}
			topPadding={50}
			key={graphInterval}
		/>
	)
}
