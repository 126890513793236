// DateTime
import { DateTime } from "@/lib/dates"
import { dateFormat } from "@/misc/constants"

// Animations
import { Popover, Transition } from "@headlessui/react"
import { getTransitionPopoverProps } from "@/lib/animations"

// UI
import { DatePicker } from "./DatePicker"
import { Button } from "../Button"

// Icons
import { ChevronDownIcon } from "@heroicons/react/outline"

// Misc
import { classNames } from "@/lib/classnames"

export interface DateRangePickerProps {
	className?: string
	from?: string
	to?: string
	onSetFrom: Function
	onSetTo: Function
}

export function DateRangePicker({
	className,
	from,
	to,
	onSetFrom,
	onSetTo,
}: DateRangePickerProps) {
	return (
		<div className={classNames("flex items-center gap-4", className)}>
			<Popover.Group className="flex items-center divide-x divide-gray-200">
				<Popover className="relative z-20 inline-block text-left">
					<Popover.Button
						as="div"
						className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900"
					>
						<Button variant="transparent" size="small">
							<span className="mr-2">
								{DateTime.fromISO(String(from)).toFormat(
									dateFormat,
								)}
							</span>

							<ChevronDownIcon
								className="ml-2 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
								aria-hidden="true"
							/>
						</Button>
					</Popover.Button>

					<Transition {...getTransitionPopoverProps()}>
						<Popover.Panel className="absolute left-0 mt-2 origin-top-left rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
							<div
								className="min-w-[300px]"
								data-testid="daterangepicker-from"
							>
								<DatePicker
									selectedDate={DateTime.local()}
									onSelectDate={(date) => {
										const newDate =
											DateTime.fromObject(date).endOf(
												"day",
											)
										onSetFrom(newDate.toJSDate())
									}}
								/>
							</div>
						</Popover.Panel>
					</Transition>
				</Popover>
			</Popover.Group>
			<span className="text-sm text-gray-500">to</span>
			<Popover.Group className="flex items-center divide-x divide-gray-200">
				<Popover className="relative z-20 inline-block text-left">
					<Popover.Button
						as="div"
						className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900"
					>
						<Button variant="transparent" size="small">
							<span className="mr-2">
								{DateTime.fromISO(String(to)).toFormat(
									dateFormat,
								)}
							</span>
							<ChevronDownIcon
								className="ml-2 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
								aria-hidden="true"
							/>
						</Button>
					</Popover.Button>

					<Transition {...getTransitionPopoverProps()}>
						<Popover.Panel className="absolute left-0 mt-2 origin-top-left rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
							<div
								className="min-w-[300px]"
								data-testid="daterangepicker-to"
							>
								<DatePicker
									selectedDate={DateTime.local()}
									onSelectDate={(date) => {
										const newDate =
											DateTime.fromObject(date).endOf(
												"day",
											)
										console.log(newDate.toJSDate())
										onSetTo(newDate.toJSDate())
									}}
								/>
							</div>
						</Popover.Panel>
					</Transition>
				</Popover>
			</Popover.Group>
		</div>
	)
}
