// TODO: This should reflect src/pages folder structure
export enum Pages {
	// Auth / Login related pages
	Register = "/account/register",
	RegisterConfirm = "/account/confirm",
	Login = "/account/login",
	RecoverPassword = "/account/recover",
	ResetPassword = "/account/reset",

	// Statistics
	StatisticsPlatform = "/stats/platform",
	StatisticsPlatformEndex = "/stats/platform/endex",
	StatisticsPlatformSingleProject = "/stats/project",
	StatisticsMySavings = "/stats/savings",

	// Investments
	Investments = "/investments",
	InvestmentsProjects = "/investments/projects",
	InvestmentsProjectsDashboard = "/investments/projects/dashboard",
	InvestmentsProjectsFiscalOverview = "/investments/projects/dashboard/fiscal-overview",
	InvestmentsProjectsProduction = "/investments/projects/dashboard/production",
	ProvisionalInvestments = "/investments/projects/dashboard/provisional",
	Communications = "/investments/projects/dashboard/messages",
	InvestmentsProjectsPayments = "/investments/projects/dashboard/payments",

	// Finance B2B
	Finance = "/finance",
	FinanceProjects = "/finance/projects",
	FinanceInterestPayments = "finance/interest-payments",
	FinanceSolarInterestTariff = "/finance/solar-interest-tariff",
	FinanceBondsloan = "/finance/bonds-loan",

	// Investor profile
	InvestorProfile = "/investor",
	InvestorIdentity = "/investor/identity",
	InvestorTest = "/investor/test",
	InvestorRisk = "/investor/risk",
	InvestorFunds = "/investor/funds",

	// Settings
	AppSettings = "/settings/profile/app",
	SettingsProfilePersonalDetails = "/settings/profile",
	SettingsProfileCompanyDetails = "/settings/profile/company-details",
	SettingsProfileBankDetails = "/settings/profile/bank-details",
	SettingsProfileChangePassword = "/settings/profile/change-password",
	SettingsProfileNotifications = "/settings/profile/notifications",
	SettingsProfileSecurity = "/settings/profile/security",
	SettingsProfileSecurityRegenerateBackupCodes = "/settings/profile/security/regenerate-backup-codes",
	Recent = "/settings/profile/activity",

	// Admin
	Info = "/settings/app/info",
}

// These routes are allowed WITHOUT user authentication
export const PAGES_ALLOW_UNAUTH_ERROR = [
	Pages.Login,
	Pages.Register,
	Pages.RegisterConfirm,
	Pages.RecoverPassword,
	Pages.ResetPassword,
	Pages.StatisticsPlatform,
	Pages.StatisticsPlatformEndex,
]
