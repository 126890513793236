import { Fragment, ReactNode, useCallback, useMemo, useRef } from "react"
import { Axis, Orientation, TickRendererProps } from "@visx/axis"
import { curveMonotoneX } from "@visx/curve"
import { localPoint } from "@visx/event"
import { GridRows } from "@visx/grid"
import { Group } from "@visx/group"
import { MarkerCircle } from "@visx/marker"
import { scaleBand, scaleLinear } from "@visx/scale"
import { LinePath } from "@visx/shape"
import { Text } from "@visx/text"
import { useTooltip } from "@visx/tooltip"
import { AnimatePresence, motion } from "@/lib/animations"
import { DateTime } from "@/lib/dates"
import { useLang } from "@/context/lang"
import { useTrans } from "@/i18n"
import { classNames } from "@/lib/classnames"
import { ParentSize } from "../../../visx/components/ParentSize"
import { findLongestArrayInMultiGraphData } from "../../../visx/lib/MultiGraph"
import { isMobile } from "react-device-detect"
import { TooltipAdapter } from "../../../visx/components/TooltipAdapter"
import { MultiGraphTooltipProps } from "./MultiGraphContent"

// Only show the bar data grid rows if there is no line datas.
// We do this to prevent having 2 axes renders, with grid rows, since
// it can be very confusing.
const SHOW_LINE_DATA_GRID_ROWS = false

export type GraphData = {
	x: string
	y: number
}

export type BarGraphData = {
	data: Array<GraphData>
	id: string
	variant?: "primary" | "default"
}

export type LineGraphData = {
	data: Array<GraphData>
	id: string
	variant?: "primary" | "default"
}

type ValueFormatter = (value: string | number) => string | number

export type MultiGraphProps = {
	width: number
	height: number
	margin?: { top: number; right: number; bottom: number; left: number }
	barDatas?: Array<BarGraphData>
	lineDatas?: Array<LineGraphData>
	xTickComponent?: (tickRendererProps: TickRendererProps) => ReactNode
	yTickComponent?: (tickRendererProps: TickRendererProps) => ReactNode
	prepareTooltipValues?:
		| ((x: TooltipData["x"]) => MultiGraphTooltipProps["values"])
		| null
	prepareTooltipLabel?:
		| ((x: TooltipData["x"]) => MultiGraphTooltipProps["label"])
		| null
}

export function MultiGraphContainer(
	props: Omit<MultiGraphProps, "width" | "height">,
) {
	return (
		<ParentSize>
			{({ width, height }) => {
				if (width < 10) return null
				return <MultiGraph {...props} width={width} height={height} />
			}}
		</ParentSize>
	)
}

MultiGraphContainer.defaultMargin = { top: 25, bottom: 40, left: 66, right: 25 }
MultiGraphContainer.barVariantColourMap = {
	primary: "#FFD900",
	default: "#4e5155",
}
MultiGraphContainer.lineVariantColourMap = {
	primary: "#149AE5",
	default: "#000",
}

MultiGraphContainer.buildBarDataTooltip = (
	d: BarGraphData,
	x: string,
	valueFormatter: ValueFormatter,
) => {
	const item = d.data.find((node) => node.x === x)

	return {
		value: valueFormatter(item ? getY(item).toFixed(2) : 0),
		id: d.id,
		variant: d.variant,
		colour:
			d.variant === "primary"
				? MultiGraphContainer.barVariantColourMap.primary
				: MultiGraphContainer.barVariantColourMap.default,
	}
}

MultiGraphContainer.buildLineDataTooltip = (
	d: LineGraphData,
	x: string,
	valueFormatter: ValueFormatter = (x) => x,
) => {
	const item = d.data.find((node) => node.x === x)
	const value = item ? getY(item).toFixed(2) : null

	return {
		value: value !== null ? valueFormatter(value) : null,
		id: d.id,
		variant: d.variant,
		colour:
			d.variant === "primary"
				? MultiGraphContainer.lineVariantColourMap.primary
				: MultiGraphContainer.lineVariantColourMap.default,
	}
}

export { MultiGraphContainer as MultiGraph }

const brandGray = "#4e5155"
const grayLight = "#C7C8CC"

const getX = (d: GraphData) => d.x
const getY = (d: GraphData) => Number(d.y)

interface TooltipData {
	x: GraphData["x"]
	topY: number
	centerX: number
}

function MultiGraph({
	width,
	barDatas: barDatasFromProps = [],
	lineDatas: lineDatasFromProps = [],
	height,
	margin = MultiGraphContainer.defaultMargin,
	xTickComponent,
	yTickComponent = DefaultYTickComponent,
	prepareTooltipValues,
	prepareTooltipLabel,
}: MultiGraphProps) {
	const { formatDate, formatNumber } = useLang()

	// flatten all datas into one array for picking
	const barDatas = useMemo(() => {
		if (barDatasFromProps.length > 1) {
			return barDatasFromProps[0].data.map((item, i) => {
				// the idea here is to get the maximum Y value
				// to help with the band and linear scales below
				// otherwise the graph doesn't know the highest y value
				return Object.assign({}, item, {
					// find the largest y by using Math.max
					// we can make a new array with all the items values
					y: Math.max(...barDatasFromProps.map((d) => d.data[i].y)),
				})
			})
		}
		return barDatasFromProps[0]?.data ?? []
	}, [barDatasFromProps])

	// flatten all datas into one array for picking
	const lineDatas = useMemo(() => {
		if (lineDatasFromProps.length > 1) {
			const longestData =
				findLongestArrayInMultiGraphData(lineDatasFromProps)
			return longestData.data.map((item, i) => {
				// the idea here is to get the maximum Y value
				// to help with the band and linear scales below
				// otherwise the graph doesn't know the highest y value
				return Object.assign({}, item, {
					// find the largest y by using Math.max
					// we can make a new array with all the items values
					y: Math.max(
						...lineDatasFromProps.map((d) => d.data[i]?.y ?? 0),
					),
				})
			})
		}
		return lineDatasFromProps[0]?.data ?? []
	}, [lineDatasFromProps])

	// bounds
	const innerWidth = width - margin.left - margin.right
	const innerHeight = height - margin.top - margin.bottom

	// scales
	const xScale = useMemo(
		() =>
			scaleBand<string>({
				range: [0, innerWidth],
				round: true,
				domain: barDatas.map(getX),
				padding: 0.4,
			}),
		[innerWidth, barDatas],
	)
	const yScale = useMemo(
		() =>
			scaleLinear<number>({
				range: [innerHeight, 0],
				round: true,
				domain: [0, Math.max(...barDatas.map(getY))],
			}),
		[innerHeight, barDatas],
	)

	const xScaleLines = useMemo(
		() =>
			scaleBand<string>({
				range: [0, innerWidth],
				round: true,
				domain: lineDatas.map(getX),
				padding: 0.4,
			}),
		[innerWidth, lineDatas],
	)
	const yScaleLines = useMemo(
		() =>
			scaleLinear<number>({
				range: [innerHeight, 0],
				round: true,
				domain: [0, Math.max(...lineDatas.map(getY))],
			}),
		[innerHeight, lineDatas],
	)

	const {
		tooltipData,
		tooltipLeft = 0,
		tooltipTop,
		showTooltip: showTooltipRaw,
		hideTooltip,
		tooltipOpen,
	} = useTooltip<TooltipData>()

	const showTooltip = useCallback(showTooltipRaw, [showTooltipRaw])

	const barWidth = xScale.bandwidth()

	const preparedTooltipData = useMemo(() => {
		if (!tooltipOpen || !tooltipData?.x) return null

		return {
			label: prepareTooltipLabel
				? prepareTooltipLabel(tooltipData.x)
				: formatDate(DateTime.fromISO(tooltipData.x), {
						variant: "pretty",
				  }),
			values: prepareTooltipValues
				? prepareTooltipValues(tooltipData.x)
				: [
						...Array(barDatasFromProps.length)
							.fill(true)
							.map((_, index) => {
								return MultiGraphContainer.buildBarDataTooltip(
									barDatasFromProps[index],
									tooltipData.x,
									(value) => {
										return formatNumber(
											typeof value === "string"
												? parseFloat(value)
												: value,
										)
									},
								)
							})
							// keep primary variants on top of tooltip
							.sort((a) => {
								if (a.variant === "primary") return -1
								return 1
							}),
						...Array(lineDatasFromProps.length)
							.fill(true)
							.map((_, index) => {
								return MultiGraphContainer.buildLineDataTooltip(
									lineDatasFromProps[index],
									tooltipData.x,
								)
							})
							// keep primary variants on top of tooltip
							.sort((a) => {
								if (a.variant === "primary") return -1
								return 1
							}),
				  ],
		}
	}, [
		barDatasFromProps,
		formatDate,
		lineDatasFromProps,
		prepareTooltipLabel,
		formatNumber,
		prepareTooltipValues,
		tooltipData?.x,
		tooltipOpen,
	])

	const timeout = useRef<NodeJS.Timeout>(null!)
	const gridRowsWidth = width - margin.left - margin.right
	const hasLineDatas = lineDatas.length > 0

	return (
		<div className="relative">
			<svg width={width} height={height}>
				{/**
				 * Only show the bar data grid rows if there is no line datas.
				 * We do this to prevent having 2 axes renders, with grid rows, since
				 * it can be very confusing.
				 */}
				{useMemo(
					() =>
						!hasLineDatas ? (
							<GridRows
								scale={yScale}
								width={gridRowsWidth}
								height={height - margin.top}
								numTicks={3}
								stroke={grayLight}
								strokeDasharray={"10,2"}
								opacity={0.75}
								top={margin.top}
								left={margin.left}
							/>
						) : null,
					[
						gridRowsWidth,
						hasLineDatas,
						height,
						margin.left,
						margin.top,
						yScale,
					],
				)}
				{SHOW_LINE_DATA_GRID_ROWS ? (
					<GridRows
						scale={yScaleLines}
						width={gridRowsWidth}
						height={height - margin.top}
						numTicks={3}
						stroke={grayLight}
						strokeDasharray={"10,2"}
						opacity={0.75}
						top={margin.top}
						left={margin.left}
					/>
				) : null}
				{/**
				 * This one big group is doing a lot:
				 * - It includes and renders all the bar graph datas
				 * - It includes and renders all the line graph datas
				 * - It includes and renders the hover/interactive part
				 */}
				<Group>
					{/**
					 * These are all the bar datas.
					 */}
					{useMemo(
						() =>
							barDatasFromProps
								// render primary variant on top
								.sort((a) => {
									if (a.variant === "primary") return 1
									return -1
								})
								.map(({ data, id, variant }) => {
									return (
										<Fragment key={id}>
											{data.map((d, index) => {
												const valueX = getX(d)
												const valueY = getY(d)
												const x = xScale(valueX) || 0
												const y =
													Math.max(
														yScale(valueY),
														0,
													) || 0

												const barHeight =
													innerHeight - y
												const barX = x + margin.left
												const barY =
													innerHeight -
													barHeight +
													margin.top

												return (
													<motion.rect
														key={`bar-${id}-${valueX}`}
														className={classNames(
															"visx-bar",
															// hover states for bar graph
															variant ===
																"primary"
																? tooltipData?.x ===
																  d.x
																	? "fill-primary-600"
																	: "fill-primary-500-transparent"
																: tooltipData?.x ===
																  d.x
																? "fill-gray-400"
																: "fill-gray-300-transparent",
														)}
														x={barX}
														width={barWidth}
														initial={{
															height: 0,
															y: barHeight + barY,
														}}
														animate={{
															height: barHeight,
															y: barY,
														}}
														transition={{
															ease: "easeOut",
															duration: 0.3,
															delay:
																(index /
																	data.length) *
																	0.5 +
																0.25,
														}}
													/>
												)
											})}
										</Fragment>
									)
								}),
						[
							barWidth,
							barDatasFromProps,
							innerHeight,
							margin.left,
							margin.top,
							tooltipData?.x,
							xScale,
							yScale,
						],
					)}
					{/**
					 * These are all the line datas.
					 */}
					{useMemo(
						() =>
							lineDatasFromProps
								// keep primary variants on top of tooltip
								.sort((a) => {
									if (a.variant === "primary") return -1
									return 1
								})
								.map(({ data, id, variant }) => {
									return (
										<LinePath
											key={id}
											curve={curveMonotoneX}
											data={data}
											x={(d) =>
												(xScaleLines(getX(d)) ?? 0) +
												margin.left +
												barWidth / 2
											}
											y={(d) =>
												(yScaleLines(getY(d)) ?? 0) +
												margin.top
											}
											stroke={
												variant === "primary"
													? MultiGraphContainer
															.lineVariantColourMap
															.primary
													: MultiGraphContainer
															.lineVariantColourMap
															.default
											}
											strokeWidth={2}
											strokeDasharray={
												variant === "primary"
													? ""
													: "6,9"
											}
											shapeRendering="geometricPrecision"
											markerMid={`url(#marker-circle-${variant})`}
											markerStart={`url(#marker-circle-${variant})`}
											markerEnd={`url(#marker-circle-${variant})`}
											clipPath="url(#line-mask)"
										/>
									)
								}),
						[
							barWidth,
							lineDatasFromProps,
							margin.left,
							margin.top,
							xScaleLines,
							yScaleLines,
						],
					)}
					{/**
					 * This is the mask for the line
					 */}
					{useMemo(
						() => (
							<clipPath id="line-mask">
								<motion.rect
									x={0}
									y={0}
									height={innerHeight + margin.top}
									fill="white"
									initial={{ width: 0 }}
									animate={{
										width: innerWidth + margin.left,
									}}
									transition={{
										duration: 1.75,
										delay: 0.75,
									}}
								/>
							</clipPath>
						),
						[innerHeight, innerWidth, margin.left, margin.top],
					)}
					{/**
					 * These are svg markers that the line data
					 * can reference.
					 */}
					{useMemo(() => {
						const variants = Object.entries(
							MultiGraphContainer.lineVariantColourMap,
						)
						return (
							<>
								{variants.map(([key, value]) => {
									return (
										<MarkerCircle
											key={key}
											id={`marker-circle-${key}`}
											fill={value}
											size={2}
											refX={2}
										/>
									)
								})}
							</>
						)
					}, [])}
					{/**
					 * These `rects` are used to highlight the selected bar
					 * So the only render transparent blocks with which the user
					 * can hover over with mouse or touch.
					 */}
					{useMemo(() => {
						return (
							<>
								{Array(barDatasFromProps[0]?.data?.length ?? 0)
									.fill(true)
									.map((_, index) => {
										const d =
											barDatasFromProps[0]?.data?.[index]
										const x = getX(d)
										const value = getY(d)
										const y = yScale(value) ?? 0

										const barX =
											(xScale(x) ?? 0) + margin.left
										const barY = 0 + margin.top

										const left =
											barX + barWidth + margin.left

										const nextTooltipData: TooltipData = {
											x: d.x,
											topY: y + margin.top,
											centerX: 0, //left - barWidth + 2,
										}

										return (
											<rect
												x={barX}
												y={barY}
												key={index}
												className={classNames(
													"visx-bar",
													"fill-transparent",
												)}
												width={barWidth}
												height={innerHeight}
												onTouchStart={(event) => {
													const y =
														localPoint(event)?.y ??
														barY
													clearTimeout(
														timeout.current,
													)

													showTooltip({
														tooltipData:
															nextTooltipData,
														tooltipTop: y,
														tooltipLeft: left,
													})
												}}
												onTouchMove={(event) => {
													const y =
														localPoint(event)?.y ??
														barY
													clearTimeout(
														timeout.current,
													)

													showTooltip({
														tooltipData:
															nextTooltipData,
														tooltipTop: y,
														tooltipLeft: left,
													})
												}}
												onMouseMove={(event) => {
													const y =
														localPoint(event)?.y ??
														barY
													clearTimeout(
														timeout.current,
													)

													showTooltip({
														tooltipData:
															nextTooltipData,
														tooltipTop: y,
														tooltipLeft: left,
													})
												}}
												onMouseLeave={() => {
													timeout.current =
														setTimeout(
															hideTooltip,
															1000,
														)
												}}
											/>
										)
									})}
							</>
						)
					}, [
						barDatasFromProps,
						barWidth,
						hideTooltip,
						innerHeight,
						margin.left,
						margin.top,
						showTooltip,
						xScale,
						yScale,
					])}
				</Group>
				<Axis
					key="y"
					tickComponent={yTickComponent}
					tickLabelProps={() => ({
						fill: "black",
						fontSize: 16,
						fontFamily: "Static",
						fontWeight: 700,
						textAnchor: "middle",
					})}
					top={innerHeight + margin.top - 1}
					left={margin.left}
					orientation={Orientation.bottom}
					scale={xScale}
					stroke="black"
					hideTicks
					numTicks={Math.max(4, Math.floor(innerWidth / 60))}
				/>
				<Axis
					key="bar-data-y"
					orientation={Orientation.left}
					scale={yScale}
					top={margin.top + 5}
					left={margin.left - 10}
					hideTicks
					numTicks={3}
					hideAxisLine
					tickLabelProps={() => ({
						fontSize: 20,
						color: brandGray,
						fontFamily: "Static",
						fontWeight: 700,
						opacity: 0.5,
						textAnchor: "middle",
					})}
					tickComponent={xTickComponent}
					hideZero
				/>
				{lineDatas?.length > 1 ? (
					<Axis
						key="line-data-x"
						orientation={Orientation.right}
						scale={yScaleLines}
						top={margin.top + 5}
						left={innerWidth + margin.right + 15}
						hideTicks
						numTicks={3}
						hideAxisLine
						tickLabelProps={() => ({
							fontSize: 20,
							color: brandGray,
							fontFamily: "Static",
							fontWeight: 700,
							opacity: 0.5,
							textAnchor: "middle",
						})}
						tickComponent={DefaultYTickComponentLines}
						hideZero
					/>
				) : null}
			</svg>
			<AnimatePresence>
				<>
					{preparedTooltipData && (
						<TooltipAdapter
							left={
								isMobile ? undefined : tooltipLeft - margin.left
							}
							top={isMobile ? 0 : tooltipTop}
							tooltipData={preparedTooltipData}
						/>
					)}
				</>
			</AnimatePresence>
		</div>
	)
}

function DefaultYTickComponent({
	formattedValue,
	...props
}: TickRendererProps) {
	return <Text {...props}>{formattedValue}</Text>
}

function DefaultYTickComponentLines({
	formattedValue,
	...props
}: TickRendererProps) {
	const { formatNumber } = useLang()
	const t = useTrans()

	return (
		<Text {...props}>
			{
				t("common.multi_graph.performance_ratio_axis.format", {
					value: formattedValue
						? formatNumber(parseFloat(formattedValue))
						: formattedValue,
				}) as string
			}
		</Text>
	)
}
