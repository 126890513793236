import { DateTime } from "@/lib/dates"
import { TFunction, useTrans } from "@/i18n"
import { isDevelopment } from "@/misc/helpers"

export const getDateDiff = (
	t: TFunction<string>,
	from: DateTime,
	to: DateTime,
	variant: "short_date" | "long_date" | "project.duration" = "short_date",
) => {
	if (isDevelopment() && from < to) {
		throw new Error("[getDateDiff] `from` date cannot be after `to` date")
	}

	const diff = from.diff(to, ["minutes", "hours", "days", "months", "years"])
	const type = `${variant}`

	if (Math.floor(diff.years) === 1 && diff.months) {
		return t(`${type}.year_and_month`, {
			count: Math.floor(diff.months),
			year: Math.floor(diff.years),
		})
	}
	if (diff.years) {
		return t(`${type}.year`, { count: Math.floor(diff.years) })
	}
	if (diff.months) {
		return t(`${type}.month`, { count: Math.floor(diff.months) })
	}
	if (diff.days) {
		return t(`${type}.day`, { count: Math.floor(diff.days) })
	}
	if (diff.hours) {
		return t(`${type}.hour`, { count: Math.floor(diff.hours) })
	}

	// we do not support seconds yet so just render min 1 minute ago
	return t(`${type}.minute`, {
		count: Math.max(1, Math.floor(diff.minutes)),
	})
}

export const useGetDateDiff = () => {
	const t = useTrans("common")

	return (from: DateTime, to: DateTime) => getDateDiff(t, from, to)
}

export const useShortDate = (date: DateTime) => {
	const now = DateTime.local()
	const t = useTrans("common")

	return getDateDiff(t, now, date)
}

export const useLongDate = (date: DateTime) => {
	const now = DateTime.local()
	const t = useTrans("common")

	return getDateDiff(t, now, date, "long_date")
}
