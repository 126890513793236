import { Suspense, useState, createContext } from "react"
import { AnimatePresence, motion } from "@/lib/animations"
import { Helmet } from "@/lib/seo"

import { ErrorBoundaryWithErrorState } from "@/components/errors/ErrorBoundary"

// Queries
import { useFinancePerformanceQuery } from "@/api/graphql"

// Translations
import { useTrans } from "@/i18n"

// Analytics
import { sendEvent } from "@/lib/analytics"

// Router
import { useNavigate } from "@/lib/router"
import { Pages } from "@/misc/pages"

// UI
import { CardWrapper } from "@/components/Card"
import { Checkbox } from "@/components/form-controls/Checkbox"
import { Label } from "@/components/form-controls/Label"
import { GraphLabels } from "@/components/graphs/GraphLabels"
import { ProjectSelector } from "@/components/ProjectSelector"
import { FinanceProjectSubMenuTabs } from "../components/FinanceProjectSubMenuTabs"

// Page Template
import { PageTemplate } from "@/templates/PageTemplate"

// Hooks
import { useCurrentFinanceProjectId } from "../hooks/useCurrentFinanceProjectId"

// Graphs
import { ProductionGraphActions } from "@/components/graphs/types/bar/ProductionGraphActions"
import { ProductionCumulative } from "@/components/graphs/types/stacked-area/ProductionCumulative"
import { AreaGraph } from "@/components/graphs/types/area/AreaGraph"
import {
	ProductionGraph,
	useProductionGraphContext,
} from "@/components/graphs/types/bar/ProductionGraph"
import { FinanceGraphCumulativeProduction } from "../components/FinanceGraphCumulativeProduction"
import { FinanceGraphProductionDaily } from "../components/FinanceGraphDailyProduction"
import { FinanceGraphProduction } from "../components/FinanceGraphProduction"
import { FinanceGraphProductionHeader } from "../components/FinanceGraphProductionHeader"
import { FinanceGraphProductionTargetedPerformanceRatio } from "../components/FinanceGraphProductionTargetedPerformanceRatio"
import { ProductionFactorTable } from "../components/ProductionFactorTable"

// DateTime
import { DateTime } from "@/lib/dates"

interface FinancePerformanceContextType {
	selectedDateRangeStart: Date
	setSelectedDateRangeStart: (selectedDateRangeStart: Date) => void
	selectedDateRangeEnd: Date
	setSelectedDateRangeEnd: (selectedDateRangeEnd: Date) => void
}

export const FinancePerformanceContext =
	createContext<FinancePerformanceContextType>(null!)

function useCurrentFinanceProject() {
	const projectId = useCurrentFinanceProjectId()
	const { data } = useFinancePerformanceQuery()

	return data?.projects?.find((project) => project?.id === String(projectId))
}

/**
 * FinancePerformanceContextProvider
 * @param param0
 * @returns
 */
function FinancePerformanceContextProvider({ children }: { children: any }) {
	// State
	const [selectedDateRangeStart, setSelectedDateRangeStart] = useState(
		DateTime.local().toJSDate(),
	)
	const [selectedDateRangeEnd, setSelectedDateRangeEnd] = useState(
		DateTime.local().toJSDate(),
	)

	return (
		<FinancePerformanceContext.Provider
			value={{
				selectedDateRangeStart,
				setSelectedDateRangeStart,
				selectedDateRangeEnd,
				setSelectedDateRangeEnd,
			}}
		>
			{children}
		</FinancePerformanceContext.Provider>
	)
}

/**
 * FinancePerformance
 * @returns
 */
export function FinancePerformance() {
	const projectId = useCurrentFinanceProjectId()
	const currentProject = useCurrentFinanceProject()
	const navigate = useNavigate()

	// Translation
	const t = useTrans("finance")

	return (
		<>
			<Helmet>
				<title>
					{t("finance:finance.performance.title", {
						projectName: currentProject?.name,
					})}
				</title>
			</Helmet>
			<PageTemplate
				title={t("finance:finance.performance.title", {
					projectName: currentProject?.name,
				})}
				topRightComponent={
					<ProjectSelector
						projectId={projectId}
						onChange={(id: string) => {
							sendEvent("finance", "performance_project_filter", {
								label: id,
							})
							navigate(`${Pages.FinanceProjects}/${id}`)
						}}
					/>
				}
				backHref={Pages.FinanceProjects}
				tabs={<FinanceProjectSubMenuTabs />}
			>
				{/** Context */}
				<FinancePerformanceContextProvider>
					<div className="grid grid-cols-1 gap-8 md:grid-cols-4">
						<div className="flex flex-col gap-8 md:col-span-4 md:flex-row">
							{/** Daily production */}
							<CardWrapper className="md:w-2/3">
								<ErrorBoundaryWithErrorState>
									<AreaGraph>
										<AreaGraph.Content>
											<FinanceGraphProductionDaily />
										</AreaGraph.Content>
									</AreaGraph>
								</ErrorBoundaryWithErrorState>
							</CardWrapper>
							<ProductionFactorTable className="md:w-1/3" />
						</div>

						{/** Periodic production */}
						<CardWrapper className="md:col-span-4">
							<ErrorBoundaryWithErrorState>
								<ProductionGraph>
									<ProductionGraph.Header>
										<FinanceGraphProductionHeader />
									</ProductionGraph.Header>
									<ProductionGraph.Actions>
										<FinanceGraphActions />
									</ProductionGraph.Actions>
									<ProductionGraph.Content>
										<FinanceGraphProduction />
									</ProductionGraph.Content>
									<ProductionGraph.Footer>
										<GraphLabels className="p-3 px-4 sm:p-5 sm:px-6">
											<GraphLabels.TotalProduction />
											<GraphLabels.ExpectedProduction />
											<GraphLabels.PerformanceRatio />
											<GraphLabels.ExpectedPerformanceRatio />
										</GraphLabels>
									</ProductionGraph.Footer>
								</ProductionGraph>
							</ErrorBoundaryWithErrorState>
						</CardWrapper>

						{/** Cumulative production */}
						<CardWrapper className="md:col-span-4">
							<ErrorBoundaryWithErrorState>
								<ProductionCumulative>
									<ProductionCumulative.Content>
										<FinanceGraphCumulativeProduction />
									</ProductionCumulative.Content>
									<ProductionCumulative.Footer>
										<GraphLabels className="px-4 pb-3 sm:px-6 sm:pb-5">
											<GraphLabels.TotalProduction />
											<GraphLabels.ExpectedProduction />
										</GraphLabels>
									</ProductionCumulative.Footer>
								</ProductionCumulative>
							</ErrorBoundaryWithErrorState>
						</CardWrapper>
					</div>
				</FinancePerformanceContextProvider>
			</PageTemplate>
		</>
	)
}

function FinanceGraphActions() {
	const t = useTrans("finance")
	const { knmiDataStatus, setKnmiDataStatus } = useProductionGraphContext()

	return (
		<div className="items-center space-y-4 lg:flex lg:space-x-8 lg:space-y-0">
			<AnimatePresence>
				<Suspense fallback={null}>
					<motion.div
						animate={{ opacity: 1 }}
						initial={{ opacity: 0 }}
						exit={{ opacity: 0 }}
					>
						<FinanceGraphProductionTargetedPerformanceRatio />
					</motion.div>
				</Suspense>
			</AnimatePresence>
			<Label className="!mb-0 flex">
				<Checkbox
					id="knmi"
					name="knmi"
					className="mr-2"
					checked={knmiDataStatus === "included"}
					onChange={(e) => {
						setKnmiDataStatus(
							e.target.checked ? "included" : "excluded",
						)
					}}
				/>

				{t("finance.production_graph.toggle_knmi")}
			</Label>
			<ProductionGraphActions />
		</div>
	)
}
